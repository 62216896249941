import React from 'react';
import styles from "./LoaderDots.module.css";

const LoaderDots = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
    </div>
  );
};

export default LoaderDots;
