import { useState, useEffect } from "react";
import { RiCloseLine } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { createUpdateSubscriber, listSubscribers } from "../../../../services/api-service";
import { SharingSelect } from "./SharingSelect";
import styles from './ShareManager.module.css';
import { Auth } from 'aws-amplify';
import { BeatLoader } from 'react-spinners';


const ShareManager = ({ onClose, collectionName, userPerm }) => {
  const { collection_id } = useParams();
  const [selectedValue, setSelectedValue] = useState('Subscriber');
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [shareList, setShareList] = useState([]);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [isLoading, setIsLoading] = useState(true);

  const handleSelect = (index, newRole) => {
    const updatedList = [...shareList];
    updatedList[index].role = newRole;
    setShareList(updatedList);
    console.log(updatedList[index]);
    createUpdateSubscriber(collection_id, updatedList[index].email, updatedList[index].role);
    fetchShares();
  };
  

  const getEmail = async () => {
    const currentUserInfo = await Auth.currentAuthenticatedUser();
    const userEmail = currentUserInfo.attributes.email;
    setCurrentUser(userEmail);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchShares();
    getEmail();
  }, [collection_id]);

  const fetchShares = async () => {
    setIsLoading(true);
    try {
      const shares = await listSubscribers(collection_id);
      setShareList(shares);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
    setIsLoading(false);
  };

  const handleSubmit = () => {
    //setIsValidEmail(emailRegex.test(email) || email === "");
    if (isValidEmail && email !== "") {
      createUpdateSubscriber(collection_id, email, selectedValue).then(responseCode => {
        console.log(responseCode);
        switch (responseCode) {
          case 0:
            setMessage('Subscriber successfully created!');
            setMessageType(styles.successText);
            setEmail("");
            fetchShares();
            break;
          case 1:
            setMessage('Access denied, please contact collection owner.');
            setMessageType(styles.errorText);
            break;
          case 2:
            setMessage('User email not found.');
            setMessageType(styles.errorText);
            break;
          default:
            setMessage('An unexpected error occurred.');
            setMessageType(styles.errorText);
        }
      });
    } else {
      console.log('Invalid email');
    }
  }

  const handleChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail)
    setMessage("");
    setMessageType("");
  }

  function CompareStrings({ string1, string2 }) {
    if (string1 === string2) {
      return <p className={styles.userEmail}>{string1} <strong>(me)</strong></p>;
    } else {
      return <p className={styles.userEmail}>{string1}</p>;
    }
  }

  return (
    <div className={styles.shareManagerModal}>
      <div className={styles.modalCloseButton}>
        <RiCloseLine onClick={onClose} size="45px" />
      </div>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h3>{`Share "${collectionName}"`}</h3>
        </div>
        <div className={styles.addPermissions}>
          <p className={styles.miniHeader}>Add User</p>
          <p className={styles.subText}>Enter valid email address, select role type, and click "add"</p>
          <div className={styles.emailInputRow}>
            <input
              className={styles.emailInput}
              type="email"
              value={email}
              onChange={(e) => handleChange(e)}
              placeholder="Enter Email"
            />
          </div>
          <div className={styles.emailPermissionRow}>
            <SharingSelect className={styles.roleSelect} selectedValue={selectedValue} setSelectedValue={setSelectedValue} permLevel={userPerm} />
            <button className={styles.submitButton} onClick={() => handleSubmit()}>Add</button>
          </div>
        </div>
        <div className={styles.messageRow} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {!isValidEmail && <p className={styles.errorText}>Please enter a valid email address.</p>}
          {message && <p className={messageType}>{message}</p>}
        </div>

        <div className={styles.managePermissions}>
          <p className={styles.miniHeader}>User List</p>
          {Array.isArray(shareList) ? (
            shareList.map((item, index) => (
              <div className={styles.userRow} key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <CompareStrings string1={item.email} string2={currentUser} />
                {item.role === "Owner" || userPerm === 1 ? (
                  <p className={styles.unchangableUserRole}>{item.role}</p>
                ) : (
                  <select
                    className={styles.changableUserRole}
                    value={item.role}
                    onChange={(e) => handleSelect(index, e.target.value)}
                  >
                    <option value={"Subscriber"}>Subscriber</option>
                    <option value={"Collaborator"}>Collaborator</option>
                    <option value={"Admin"}>Admin</option>
                    <option className={styles.removeOption} value={"Remove"}>Remove</option>
                  </select>
                )}
              </div>
            ))
          ) : (
            <p>No users have access to this collection.</p>
          )}
          {(isLoading === true) && (<div className={styles.isLoading}><BeatLoader /></div>)}
        </div>
      </div>
    </div>
  );
};

export default ShareManager;