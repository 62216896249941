import React, { useState } from "react";
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import styles from './NCUAQuery.module.css';

const NCUAQuery = () => {
  const [question, setQuestion] = useState("");
  const [response, setResponse] = useState({sql: '', response: ''});
  const [isLoading, setIsLoading] = useState(false);
  const [showSQL, setShowSQL] = useState(false);

  const submitQuestion = async () => {
    setIsLoading(true);
    try {
      const res = await fetch("https://api.llmpulse.com/collections/ncua/", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: question,
        }),
      });
      const data = await res.json();
      setResponse(data);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  const toggleSQL = () => setShowSQL(!showSQL);

  return (
    <div>
      <input
        type="text"
        placeholder="Ask a question..."
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        className={styles.input}
      />
      <button 
        onClick={submitQuestion}
        className={styles.button}
        disabled={isLoading}
      >
        Submit
      </button>
      {isLoading ? (
        <div className={styles.loading}>Loading...</div>
      ) : (
        <div className={styles.responseBox}>
          <ReactMarkdown remarkPlugins={[gfm]}>
            {response.response}
          </ReactMarkdown>
          <button 
            onClick={toggleSQL} 
            className={styles.sqlButton}
          >
            {showSQL ? 'Hide SQL' : 'Show SQL'}
          </button>
          {showSQL && (
            <textarea 
              readOnly 
              value={response.sql}
              className={styles.sqlField}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default NCUAQuery;
