import React, { useEffect, useState } from 'react';
import { getAmplifySessionHeaders } from '../../services/auth-service';
import { AiOutlinePlus, AiOutlineCloudUpload } from "react-icons/ai";
import { API_URL } from '../../config';
import { BeatLoader } from 'react-spinners';
import styles from './UserManagement.module.css';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const role_map = {
    0: "Owner",
    1: "Admin",
    2: "Collaborator",
    3: "Subscriber",
    // Add more key-value pairs as needed
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const headers = await getAmplifySessionHeaders();
        const response = await fetch(`${API_URL}/users/org/`, { headers });
        const data = await response.json();
        setUsers(data);
      } catch (err) {
        console.error(err);
      }
    }

    fetchData();
    setIsLoading(false);
  }, []);

  const handleEditPermissions = (user) => {
    // handle the click event here
    console.log(`Edit permissions for: ${user.name}`);
  };

  return (
    <div className={styles.mainDiv} >
      <div className={styles.headerDiv}>
        <h1 className={styles.title}>User Management</h1>
        <AiOutlinePlus className={styles.addIcon} size="2em" onClick={() => console.log("show add user modal")} />
      </div>
      <div className={styles.contentDiv}>
      <table className={styles.contentTable}>
        <thead className={styles.tableHead}>
          <tr className={styles.headRow}>
            <th className={styles.columnName}>Name</th>
            <th className={styles.columnEmail}>Email</th>
            <th className={styles.columnRole}>Role</th>
            {/*<th className={styles.columnActions}>Actions</th>*/}
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {users.map((user, index) => (
            <tr className={styles.bodyRow} key={index}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{role_map[user.role] || "Unknown"}</td>
              {/*<td>
                <button onClick={() => handleEditPermissions(user)}>Edit Permissions</button>
          </td>*/}
            </tr>
          ))}
        </tbody>
      </table>
      {(isLoading === true) && (<div className={styles.isLoading}><BeatLoader /></div>)}
      </div>
    </div>
  );
}

export default UserManagement;
