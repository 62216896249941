import React, { useState } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import styles from './EnterpriseModal.module.css';

const EnterpriseModal = ({ closeFunction }) => {
    const [text, setText] = useState('');

    async function submitEnterprise(text) {
        console.log(text);
        handleClose();
    }

    const handleClose = () => {
        closeFunction(false);
    }

    return (
        <div className={styles.modalContainer}>
            <div className={styles.closeButton} >
                <RiCloseLine onClick={handleClose} size="45px" />
            </div>
            <h2 className={styles.title}>Upgrade to Enterprise</h2>
            <textarea
                className={styles.textArea}
                placeholder="Please provide us with more details about your interest in the Enterprise plan"
                value={text}
                onChange={e => setText(e.target.value)}
            />
            <button className={styles.submitButton} onClick={() => submitEnterprise(text)}>Submit</button>
        </div>
    );
};

export default EnterpriseModal;
