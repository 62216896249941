import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RiShareLine, RiArchiveDrawerLine } from 'react-icons/ri';
import { listCollections, getCollection, putCollection, putCollectionVisibility, getPosts, collectionSubscribe } from '../../services/api-service';
import MyFeed from './components/MyFeed/MyFeed';
import SideBar from './components/SideBar/SideBar';
import ShareManager from './components/ShareManager/ShareManager';
import Modal from 'react-modal';
import Switch from "react-switch";
import DocumentListButton from './components/DocumentListButton/DocumentListButton';
import FeedWindow from './components/FeedWindow/FeedWindow';
import styles from './CollectionFeed.module.css'

const CollectionFeed = () => {
    const { collection_id } = useParams();
    const [collectionDetails, setCollectionDetails] = useState({});
    const [collectionName, setCollectionName] = useState(null);
    const [collections, setCollections] = useState([]); // stores an array of the user's collections
    const [showManageDocumentModal, setShowManageDocumentModal] = useState(false);
    const [showManageShareModal, setShowManageShareModal] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [fetchingDetails, setFetchingDetails] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if (collection_id !== undefined) {
            fetchCollectionDetails();
            setCollectionName(collectionDetails.name);
            fetchPosts();
        } else {
            setCollectionName("My Feed");
            setMessages([]);
        }
        fetchCollections();
    }, [collection_id]);

    const fetchCollectionDetails = async () => {
        setFetchingDetails(true);
        try {
            const fetchedCollection = await getCollection(collection_id);
            setCollectionDetails(fetchedCollection);
            setFetchingDetails(false);
        } catch (error) {
            console.error('Error fetching collection details:', error);
        }
    };

    const fetchCollections = async () => {
        try {
            const fetchedCollections = await listCollections();
            setCollections(fetchedCollections);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    const fetchPosts = async () => {
        setIsLoading(true);
        setMessages([]);
        const posts = await getPosts(collection_id);
        setMessages(posts);
        setIsLoading(false);
    };

    async function submitCollectionName() {
        // don't call api if the name hasn't changed
        if (collectionName === collectionDetails.name) {
            return;
        }
        try {
            let newName = collectionName;
            console.log(`colleciton name is ${newName}`);
            if (collectionName === '') {
                newName = "New Collection";
            }
            const res = await putCollection(collection_id, newName);
            fetchCollections();
            fetchCollectionDetails();
        } catch (e) {
            console.log("error when changing name");
        }
    }

    async function handleCheckboxChange() {
        setCollectionDetails(prevState => ({
            ...prevState,
            visibility: prevState.visibility === 0 ? 1 : 0
        }));

        const res = await putCollectionVisibility(collection_id, !collectionDetails.visibility);
    };

    async function handleSubscribe() {
        const res = await collectionSubscribe(collection_id);
        fetchCollectionDetails();
        fetchCollections();
        setIsSidebarOpen(false);
    };

    if ((!collectionDetails.subscribed) && (collection_id !== undefined) && (!fetchingDetails)) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <h1>{collectionDetails.name}</h1>
                <button onClick={() => { handleSubscribe() }}>
                    Subscribe
                </button>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.collectionPage}>
                <Modal isOpen={showManageDocumentModal} style={{ overlay: { zIndex: 10 }, content: { marginTop: '100px', borderRadius: '10px' } }}>
                    <DocumentListButton onClose={() => {
                        setShowManageDocumentModal(false);
                        fetchPosts();
                    }} />
                </Modal>
                <Modal isOpen={showManageShareModal} style={{ overlay: { zIndex: 10 }, content: { marginTop: '100px', borderRadius: '10px' } }}>
                    <ShareManager
                        onClose={() => setShowManageShareModal(false)}
                        collectionName={collectionDetails.name}
                        userPerm={collectionDetails.user_role}
                    />
                </Modal>
                <SideBar collection_id={collection_id} collections={collections} isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
                <div className={styles.chatColumn}>
                    <div className={styles.fixedHeader}>
                        <div className={styles.headerContent}>
                            <div className={styles.headerLeft}>
                                <h3 className={styles.headerTitle}>
                                    {(collection_id !== undefined ? collectionDetails.name : "My Feed")}
                                </h3>
                            </div>
                        </div>
                        {(collection_id !== undefined) && (collectionDetails.user_role > 0) &&
                            <div className={styles.headerRight}>
                                <h3 className={styles.publicText} style={{ marginRight: '5px' }}>Public:</h3>
                                <Switch checked={collectionDetails.visibility === 1} onChange={handleCheckboxChange} onColor="#1D2345" offColor="#888" />
                                <RiArchiveDrawerLine onClick={() => setShowManageDocumentModal(true)} color="black" size="25px" style={{ marginRight: '15px', marginLeft: '15px' }} />
                                <RiShareLine onClick={() => setShowManageShareModal(true)} color="black" size="25px" style={{ marginRight: '15px' }} />
                            </div>}
                    </div>
                    {collection_id !== undefined ? (
                        <FeedWindow collection_id={collection_id} collectionDetails={collectionDetails} postList={messages} setPostList={setMessages} isLoading={isLoading} />
                    ) : (
                        <MyFeed />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CollectionFeed;