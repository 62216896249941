import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';
import { RiClipboardLine, RiThumbUpLine, RiThumbDownLine } from 'react-icons/ri';
import { createCollectionSummaryPost, createCollectionQuestionsPost, createCollectionUpdatesPost, modifyPost, publishPost, deletePost, postFeedback } from '../../../../services/api/postAPI';
import BlinkingIndicator from '../../../../components/BlinkingIndicator/BlinkingIndicator';
import PostSettings from '../PostSettings/PostSettings';
import styles from './FeedWindow.module.css'
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.css';


Modal.setAppElement('#root');

const FeedWindow = ({ collection_id, collectionDetails, postList, setPostList, isLoading }) => {
    const chatBoxRef = useRef(null);
    const [generatingPost, setGeneratingPost] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingPostId, setEditingPostId] = useState(null);
    const [editedContent, setEditedContent] = useState('');

    function getInitials(given_name, family_name) {
        return `${given_name.charAt(0)}${family_name.charAt(0)}`;
    }

    const collectionSummaryPost = async () => {
        setGeneratingPost(true);
        try {
            const result = await createCollectionSummaryPost(collection_id);
            setGeneratingPost(false);
            setPostList((prev) => [
                result,
                ...prev,
            ]);
        } catch (error) {
            console.error('Failed to create collection summary post:', error);
            toast.error("Failed to create collection summary post.");
            setGeneratingPost(false);
        }
    }

    const collectionUpdates = async () => {
        setGeneratingPost(true);
        try {
            const result = await createCollectionUpdatesPost(collection_id);
            setGeneratingPost(false);
            setPostList((prev) => [
                result,
                ...prev,
            ]);
        } catch (error) {
            console.error('Failed to create collection update post:', error);
            toast.error("Failed to create collection update post.");
            setGeneratingPost(false);
        }
    }

    const collectionQuestions = async () => {
        setGeneratingPost(true);
        try {
            const result = await createCollectionQuestionsPost(collection_id);
            setGeneratingPost(false);
            setPostList((prev) => [
                result,
                ...prev,
            ]);
        } catch (error) {
            console.error('Failed to create collection questions post:', error);
            toast.error("Failed to create collection questions post.");
            setGeneratingPost(false);
        }
    }

    const modifyPublishStatus = (post_id) => {
        try {
            publishPost(post_id);
            const updatedPosts = postList.map(post =>
                post.post_id === post_id ? { ...post, status: post.status === 0 ? 1 : 0 } : post
            );
            setPostList(updatedPosts);
        } catch (error) {
            console.error('Failed to modify publish status for post:', error);
            toast.error("Failed to modify publish status for post.");
        }
    }

    const removePost = (post_id) => {
        try {
            deletePost(post_id);
            const updatedPosts = postList.filter(post => post.post_id !== post_id);

            // Updating the state with the filtered list
            setPostList(updatedPosts);
        } catch (error) {
            console.error('Failed to remove the post:', error);
            toast.error("Failed to delete post.");
        }
    }

    const handleFeedback = (status, post_id) => {
        const updatedPosts = postList.map(post =>
            post.post_id === post_id ? { ...post, user_feedback: status === "off" ? null : (status === "up" ? 1 : 0) } : post
        );
        postFeedback(post_id, status === "up" ? 1 : 0)
        setPostList(updatedPosts);
    }

    const editPost = (post_id, content) => {
        setIsEditing(true);
        setEditingPostId(post_id);
        setEditedContent(content);
    };

    const saveEditedPost = () => {
        modifyPost(editingPostId, editedContent); // Assuming modifyPost updates the backend.
        const updatedPosts = postList.map(post =>
            post.post_id === editingPostId ? { ...post, content: editedContent } : post
        );
        setPostList(updatedPosts);
        setIsEditing(false);
        setEditingPostId(null);
    };

    const cancelEdit = () => {
        setIsEditing(false);
        setEditingPostId(null);
    };

    const timeSince = (date) => {
        const now = moment();
        const postDate = moment(date);
        const diffInMinutes = now.diff(postDate, 'minutes');
        const diffInHours = now.diff(postDate, 'hours');
        const diffInDays = now.diff(postDate, 'days');

        if (diffInMinutes < 60) {
            return `${diffInMinutes} min`;
        } else if (diffInHours < 24) {
            return `${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
        } else {
            return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
        }
    }

    function textWithNewlines(text) {
        // First, split by newline
        let contentArray = text.split(/\n|\\n/);

        // Process each line, look for **text** patterns and replace with bold JSX
        contentArray = contentArray.map(line => {
            const boldedLine = [];
            const boldRegex = /\*\*([^*]+)\*\*/g;

            let lastIndex = 0;
            let match;

            while ((match = boldRegex.exec(line)) !== null) {
                // Push the text before the match
                boldedLine.push(line.substring(lastIndex, match.index));

                // Push the bolded text
                boldedLine.push(<strong>{match[1]}</strong>);

                lastIndex = match.index + match[0].length;
            }

            // Push remaining text after last match
            boldedLine.push(line.substring(lastIndex));

            return boldedLine;
        });

        // Interleave with <br /> elements
        contentArray = contentArray.flatMap((line, index, array) => (
            index === array.length - 1 ? line : [...line, <br key={index} />]
        ));

        return contentArray;
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                console.log("Text copied to clipboard");
            },
            (err) => {
                console.error("Could not copy text to clipboard: ", err);
                toast.error("Could not copy text to clipboard.");
            }
        );
    };

    const generateMessage = (userName, familyName, collectionName, action) => {
        switch (action) {
            case "document_addition":
                return [<strong key="user">{userName} {familyName}</strong>, " added a new document to the ", <strong key="collection">{collectionName}</strong>, " collection"];
            case "update_summary":
                return [<strong key="user">{userName} {familyName}</strong>, " posted new updates in the ", <strong key="collection">{collectionName}</strong>, " collection"];
            case "recommended_questions":
                return [<strong key="user">{userName} {familyName}</strong>, " created new questions for the ", <strong key="collection">{collectionName}</strong>, " collection"];
            case "collection_summary":
                return [<strong key="user">{userName} {familyName}</strong>, " created a new summary of the ", <strong key="collection">{collectionName}</strong>, " collection"];
            default:
                return "Invalid action provided.";
        }
    };

    return (
        <div className={styles.feedWindow}>
            <div ref={chatBoxRef} className={styles.feedList}>
                {(generatingPost) && (
                    <div className={styles.post}>
                        <div className={styles.postUser}>
                            <div className={styles.userAvatar}>
                                {getInitials("C", "U")}
                            </div>
                            <div className={styles.userName}>Generating Post</div>
                        </div>
                        <div className={styles.postContent}>
                            <BlinkingIndicator />
                        </div>
                        <div className={styles.postControls}>
                            <div className={styles.postDate}></div>
                        </div>
                    </div>
                )}
                {postList.map((post, index) => (
                    <div key={index} className={styles.post}>
                        <div className={styles.postUser}>
                            <div className={styles.userDetails}>
                                <div
                                    className={styles.userAvatar}
                                    style={{
                                        backgroundImage: post.user.avatar ? `url(${post.user.avatar})` : 'none'
                                    }}
                                >
                                    {!post.user.avatar && getInitials(post.user.given_name, post.user.family_name)}
                                </div>
                                <div className={styles.userText}>
                                    <div className={styles.userName}>{generateMessage(post.user.given_name, post.user.family_name, post.collection_name, post.type)} {(collectionDetails.user_role >= 2) && (post.status === 1) && ("PUBLISHED")}</div>
                                    <div className={styles.postDate} title={moment(post.created_at).format('YYYY-MM-DD hh:mm A')}>{timeSince(post.created_at)} ago</div>
                                </div>
                            </div>
                            <PostSettings
                                userPerm={collectionDetails.user_role}
                                status={post.status}
                                onEdit={() => editPost(post.post_id, post.content)}
                                onPublish={() => modifyPublishStatus(post.post_id)}
                                onDelete={() => removePost(post.post_id)}
                            />
                        </div>
                        <div className={styles.postContent}>
                            {isEditing && post.post_id === editingPostId ? (
                                <>
                                    <textarea
                                        className={styles.editArea}
                                        value={editedContent}
                                        onChange={(e) => setEditedContent(e.target.value)}
                                    />
                                    <div className={styles.editButtons}>
                                        <button onClick={saveEditedPost}>Save</button>
                                        <button onClick={cancelEdit}>Cancel</button>
                                    </div>
                                </>
                            ) : (
                                textWithNewlines(post.content)
                            )}
                        </div>
                        <div className={styles.postControls}>
                            <div className={styles.postIcons}>
                                <RiClipboardLine className={styles.postIcon} onClick={() => copyToClipboard(post.content)} />
                                {post.user_feedback === 1 && (
                                    <RiThumbUpLine className={styles.postIcon} style={{ color: 'green' }} onClick={() => handleFeedback('off', post.post_id)} />
                                )}
                                {post.user_feedback === 0 && (
                                    <RiThumbDownLine className={styles.postIcon} style={{ color: 'red' }} onClick={() => handleFeedback('off', post.post_id)} />
                                )}
                                {post.user_feedback === null && (
                                    <>
                                        <RiThumbUpLine className={styles.postIcon} onClick={() => handleFeedback('up', post.post_id)} />
                                        <RiThumbDownLine className={styles.postIcon} onClick={() => handleFeedback('down', post.post_id)} />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                {(isLoading === true) && (<div className={styles.isLoading}><BeatLoader /></div>)}
                {postList.length === 0 && (isLoading === false) && (
                    <div className={styles.emptyPostMessage}>
                        There's no activity in this collection yet.
                    </div>
                )}
            </div>
            <div className={styles.postCreator}>
                <button className={styles.createPostButton} onClick={collectionSummaryPost}>Summarize Collection</button>
                <button className={styles.createPostButton} onClick={collectionSummaryPost}>Summarize Document</button>
                <button className={styles.createPostButton} onClick={collectionQuestions}>Recommended Questions</button>
                <button className={styles.createPostButton} onClick={collectionUpdates}>Get Updates</button>
            </div>
        </div>
    )
}

export default FeedWindow;