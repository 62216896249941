import React, { useState, useEffect } from 'react';
import styles from './TestStripe.module.css';
import getStripe from "../../stripe/getStripe";
import Modal from 'react-modal';
import { API_URL, UI_URL, stripe_ind_monthly_pid, stripe_ind_yearly_pid, stripe_pro_monthly_pid, stripe_pro_yearly_pid } from '../../config';
import { getAmplifySessionHeaders } from '../../services/auth-service';
import { Auth } from 'aws-amplify';
import { getUser } from '../../services/api-service';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal';
import { BsFillLightbulbFill, BsFillLightningChargeFill, BsFillPersonFill } from 'react-icons/bs';
import EnterpriseModal from './components/EnterpriseModal/EnterpriseModal';

Modal.setAppElement('#root');

export default function TestStripe() {
  const [paymentMonthly, setPaymentMonthly] = useState(true);
  const [userLevel, setUserLevel] = useState(0);
  const [showIndividualModal, setShowIndividualModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const [showProfessionalModal, setShowProfessionalModal] = useState(false);
  const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);

  useEffect(() => {
    fetchUserDetails();
  }, []);

  async function fetchUserDetails() {
    const fetchedUser = await getUser();
    setUserLevel(fetchedUser.membership);
    console.log(fetchedUser.membership);
    if (fetchedUser.renewal_period === "yearly") {
      setPaymentMonthly(false);
    } else {
      setPaymentMonthly(true);
    }
  }

  async function getUserEmail() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;
      const email = attributes.email;
      console.log('User email:', email);
      return email;
    } catch (error) {
      console.error('Error getting user email:', error);
      return null;
    }
  }

  const handleSubscriptionSelect = (level) => {
    console.log(level);
    console.log(userLevel);
    if (level === 1 && userLevel === 2) {
      setShowDowngradeModal(true);
    } else if (level === 1) {
      setShowIndividualModal(true);
    } else if (level === 2 && userLevel === 1) {
      setShowUpgradeModal(true);
    } else if (level === -1) {
      setShowEnterpriseModal(true);
    } else {
      setShowProfessionalModal(true);
    }
  };

  async function handleIndividualCheckout() {
    console.log(paymentMonthly);
    console.log(stripe_ind_monthly_pid);
    console.log(stripe_ind_yearly_pid);
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: `${paymentMonthly ? stripe_ind_monthly_pid : stripe_ind_yearly_pid}`,
          quantity: 1,
        },
      ],
      mode: 'subscription',
      successUrl: `${UI_URL}/collections`,
      cancelUrl: `${UI_URL}/collections`,
      customerEmail: await getUserEmail(),
    });
    console.warn(error.message);
  }

  async function handleProfessionalCheckout() {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: `${paymentMonthly ? stripe_pro_monthly_pid : stripe_pro_yearly_pid}`,
          quantity: 1,
        },
      ],
      mode: 'subscription',
      successUrl: `${UI_URL}/collections`,
      cancelUrl: `${UI_URL}/collections`,
      customerEmail: await getUserEmail(),
    });
    console.warn(error.message);
  }

  async function handleUpgradeToProfessional() {
    const headers = await getAmplifySessionHeaders();
    // Call your backend to update the subscription
    const response = await fetch(`${API_URL}/webhooks/professional_upgrade/`, {
      method: 'GET',
      headers: headers,
    });

    const result = await response.json();

    if (result.error) {
      console.error(result.error);
    } else {
      // Redirect to the success page
      window.location.href = `${UI_URL}/collections`;
    }
  }

  async function handleDowngradeToIndividual() {
    const headers = await getAmplifySessionHeaders();
    // Call your backend to update the subscription
    const response = await fetch(`${API_URL}/webhooks/individual_downgrade/`, {
      method: 'GET',
      headers: headers,
    });

    const result = await response.json();

    if (result.error) {
      console.error(result.error);
    } else {
      // Redirect to the success page
      window.location.href = `${UI_URL}/collections`;
    }
  }

  return (
    <div className={styles.container}>
      <Modal isOpen={showIndividualModal}>
        <ConfirmationModal
          setShow={setShowIndividualModal}
          message="Are you sure you want to upgrade to the Individual plan?"
          onConfirm={handleIndividualCheckout}
        />
      </Modal>
      <Modal isOpen={showUpgradeModal}>
        <ConfirmationModal
          setShow={setShowUpgradeModal}
          message="Are you sure you want to upgrade to the Professional plan?"
          onConfirm={handleUpgradeToProfessional}
        />
      </Modal>
      <Modal isOpen={showDowngradeModal}>
        <ConfirmationModal
          setShow={setShowDowngradeModal}
          message="Are you sure you want to downgrade to the Individual plan?"
          onConfirm={handleDowngradeToIndividual}
        />
      </Modal>
      <Modal isOpen={showProfessionalModal}>
        <ConfirmationModal
          setShow={setShowProfessionalModal}
          message="Are you sure you want to upgrade to the Professional plan?"
          onConfirm={handleProfessionalCheckout}
        />
      </Modal>
      <Modal isOpen={showEnterpriseModal}>
        <EnterpriseModal closeFunction={setShowEnterpriseModal} />
      </Modal>

      <div className={styles.planContentContainer}>
        <h1 className="text-grey font-thin text-center">Choose your plan to upgrade to supercharge your productivity</h1>
        <div className={styles.toggleContainer}>
          <p>Monthly</p>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={!paymentMonthly}
              onChange={() => setPaymentMonthly(!paymentMonthly)}
            />
            <span className={styles.slider}></span>
          </label>
          <p>Yearly</p>
        </div>
        <div className={styles.optionsContainer}>
          <div className={styles.planCardContainer}>
            <div className={styles.planLevel}>
              <BsFillPersonFill /> <span className={`${styles.planLevel} ${styles.fontThin}`}>Individual</span>
            </div>
            <div className={styles.pricingSection}>
              <span className={styles.dollar}>$</span>{paymentMonthly ? '10' : '100'} <span className={styles.priceFreq}>per seat/month</span>
            </div>
            <div>
              <div className={styles.optionButtonContainer}>
                <button
                  onClick={() => handleSubscriptionSelect(1)}
                  className={`${styles.optionButton} ${userLevel === 1 ? styles.currentLevel : ''}`}
                  disabled={userLevel === 1}
                >
                  {userLevel === 0 ? 'Get Started' : userLevel === 1 ? 'Current Plan' : 'Downgrade'}
                </button>
              </div>
              <div class={styles.descriptionContainer}>
                <p>Individual Plan includes:</p>
                <ul className={`${styles.description} ${styles.thin}`}>
                  <li>Unlimited collections</li>
                  <li>Unlimited document uploads</li>
                  <li>Up to 100 queries per month</li>
                </ul>
              </div>
            </div>
          </div>

          <div className={styles.planCardContainer}>
            <div className={styles.planCardTag}>
              POPULAR
            </div>
            <div className={styles.planLevel}>
              <BsFillLightbulbFill /> <span className={`${styles.planLevel} ${styles.fontThin}`}>Professional</span>
            </div>
            <div className={styles.pricingSection}>
              <span className={styles.dollar}>$</span>{paymentMonthly ? '25' : '250'} <span className={styles.priceFreq}>per seat/month</span>
            </div>
            <div>
              <div className={styles.optionButtonContainer}>
                <button
                  onClick={() => handleSubscriptionSelect(2)}
                  className={`${styles.optionButton} ${userLevel === 2 ? styles.currentLevel : ''}`}
                  disabled={userLevel === 2}
                >
                  {userLevel === 2 ? 'Current Plan' : 'Get Started'}
                </button>
              </div>
              <div class={styles.descriptionContainer}>
                <p>Everything in Individual, plus:</p>
                <ul className={`${styles.description} ${styles.thin}`}>
                  <li>Unlimited collections</li>
                  <li>Unlimited document uploads</li>
                  <li>Up to 500 queries per month</li>
                </ul>
              </div>
            </div>
          </div>

          <div className={`${styles.planCardContainer} ${styles.dark}`}>
            <div className={styles.planLevel}>
              <BsFillLightningChargeFill className={styles.lightning} /> <span className={`${styles.planLevel} ${styles.fontThin}`}>Enterprise</span>
            </div>
            <div className={`${styles.pricingSection} ${styles.enterpriseTitle}`}>
              <span>CONTACT US</span>
            </div>
            <div>
              <div className={styles.optionButtonContainer}>
                <button
                  onClick={() => handleSubscriptionSelect(-1)}
                  className={styles.optionButton}
                >
                  Get Started
                </button>
              </div>
              <div class={styles.descriptionContainer}>
                <p>Everything in Professional, plus:</p>
                <ul className={`${styles.description} ${styles.thin}`}>
                  <li>Unlimited document uploads</li>
                  <li>Unlimited collections</li>
                  <li>Unlimited queries per month</li>
                  <li>Custom response templates</li>
                </ul>
              </div>
            </div>
          </div>

          {/* <div className={`${styles.option} ${userLevel === 0 ? styles.currentLevel : ''}`}>
          <h2>Developer</h2>
          <p className={styles.price}>$0</p>
          <ul className={styles.description}>
            <li>Basic access</li>
            <li>Limited features</li>
          </ul>
          <button
            className={styles.optionButton}
            onClick={() => handleSubscriptionSelect(0)}
            disabled={true}
          >
            Get Started
          </button>
        </div> */}
          {/* <div className={`${styles.option} ${userLevel === 1 ? styles.currentLevel : ''}`}>
          <h2>Individual</h2>
          <p className={styles.price}>${paymentMonthly ? '10' : '100'}</p>
          <ul className={styles.description}>
            <li>Full access</li>
            <li>Personal use</li>
          </ul>
          <button
            className={styles.optionButton}
            onClick={() => handleSubscriptionSelect(1)}
            disabled={userLevel === 1}
          >
            Get Started
          </button>
        </div> */}
          {/* <div className={`${styles.option} ${userLevel === 2 ? styles.currentLevel : ''}`}>
          <h2>Professional</h2>
          <p className={styles.price}>${paymentMonthly ? '25' : '250'}</p>
          <ul className={styles.description}>
            <li>Advanced features</li>
            <li>Team collaboration</li>
          </ul>
          <button
            className={styles.optionButton}
            onClick={() => handleSubscriptionSelect(2)}
            disabled={userLevel === 2}
          >
            Get Started
          </button>
        </div> */}
          {/* <div className={`${styles.option} ${userLevel === -1 ? styles.currentLevel : ''}`}>
          <h2>Enterprise</h2>
          <p className={styles.price}>Contact Us</p>
          <ul className={styles.description}>
            <li>Custom solutions</li>
            <li>Dedicated support</li>
          </ul>
          <button
            className={styles.optionButton}
            onClick={() => handleSubscriptionSelect(3)}
            disabled={true}
          >
            Contact Us
          </button>
        </div> */}
        </div>
      </div>
    </div>
  );
};