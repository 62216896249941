import styles from './ShareManager.module.css';

export const SharingSelect = ({ selectedValue, setSelectedValue, permLevel }) => {
    const options = [
        { value: 'Subscriber', label: 'Subscriber' },
        ...(permLevel === 1 ? [] : [
            { value: 'Collaborator', label: 'Collaborator' },
            { value: 'Admin', label: 'Admin' },
        ]),
    ];

    return (
        <select
            className={styles.roleSelect}
            value={selectedValue}
            onChange={(e) => setSelectedValue(e.target.value)}
        >
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};