import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Switch from "react-switch";
import { RiCloseLine, RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { BsFiletypePdf, BsLink45Deg } from "react-icons/bs";
import { AiOutlinePlus, AiOutlineCloudUpload } from "react-icons/ai";
import { getAmplifySessionHeaders } from "../../../../services/auth-service";
import { API_URL } from "../../../../config";
import styles from './DocumentListButton.module.css';
import moment from 'moment';
import { BeatLoader } from 'react-spinners';

function DocumentListButton({ onClose }) {
    const { collection_id } = useParams();
    const [documents, setDocuments] = useState([]);
    const [view, setView] = useState('IN_COLLECTION');
    const [documentStatus, setDocumentStatus] = useState({});
    const [sortField, setSortField] = useState('created_at'); // Default sorting field
    const [sortDirection, setSortDirection] = useState('desc'); // Default sorting direction
    const [isLoading, setIsLoading] = useState(true);


    const fetchDocuments = async (view) => {
        setIsLoading(true);
        setDocuments([]);
        const headers = await getAmplifySessionHeaders();
        const endpoint = view === 'IN_COLLECTION' ? 'collection_documents' : 'org_documents';
        const response = await fetch(`${API_URL}/collections/${endpoint}/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ collection_id })
        });

        if (response.ok) {
            const data = await response.json();
            setDocuments(sortData(data, sortField, sortDirection));
            if (view === 'IN_COLLECTION' && data.length === 0) {
                setView('ALL');
            }
        }
        setIsLoading(false);
    };

    // Function to sort data based on selected field and direction
    const sortData = (data, field, direction) => {
        return [...data].sort((a, b) => {
            if (field === 'created_at') {
                const aValue = moment(a[field]);
                const bValue = moment(b[field]);
                return direction === 'asc' ? aValue.diff(bValue) : bValue.diff(aValue);
            } else {
                const aValue = a[field];
                const bValue = b[field];
                return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
        });
    };
    

    // Function to handle sorting when clicked
    const handleSort = (field) => {
        const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(newDirection);
        setDocuments(sortData(documents, field, newDirection));
    };

    useEffect(() => {
        setIsLoading(true);
        fetchDocuments(view);
    }, [collection_id, view]);

    const handleToggle = async (documentId, inCollection) => {
        // Update the local checked status immediately
        setDocumentStatus((prevStatus) => ({
            ...prevStatus,
            [documentId]: !inCollection
        }));

        const headers = await getAmplifySessionHeaders();
        const url = inCollection ? `${API_URL}/collections/documents/remove/` : `${API_URL}/collections/documents/add/`;
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ document_id: documentId, collection_id })
        });

        if (!response.ok) {
            // If API call fails, revert the local checked status
            setDocumentStatus((prevStatus) => ({
                ...prevStatus,
                [documentId]: inCollection
            }));
        } else {
            // If successful, update documents
            fetchDocuments(view);
        }
    };

    const truncateTitle = (title, limit = 40) => {
        return title.length > limit ? title.substring(0, limit) + "..." : title;
    };

    return (
        <div className={styles.manageDocumentModal}>
            <div className={styles.manageDocumentModalCloseButton}>
                <RiCloseLine onClick={onClose} size="45px" />
            </div>
            <div className={styles.manageDocumentModalHeader}>
                <h3>Manage Collection Documents</h3>
            </div>
            <div className={styles.documentViewSelectDiv}>
                <button className={`${styles.collectionDocButtons} ${view === 'IN_COLLECTION' ? styles.selectedButton : ''}`} onClick={() => setView('IN_COLLECTION')}>In Collection</button>
                <button className={`${styles.collectionDocButtons} ${view === 'ALL' ? styles.selectedButton : ''}`} onClick={() => setView('ALL')}>All</button>
            </div>
            <div className={styles.contentDiv}>
                <table className={styles.contentTable}>
                    <thead className={styles.tableHead}>
                        <tr className={styles.headRow}>
                            <th className={styles.columnType}></th>
                            <th className={styles.columnTitle}>
                                Title
                                {sortField === 'title' && sortDirection === 'asc' ? <RiArrowUpSLine onClick={() => handleSort('title')} /> : <RiArrowDownSLine onClick={() => handleSort('title')} />}
                            </th>
                            <th className={styles.columnTitle}>
                                Upload Date
                                {sortField === 'created_at' && sortDirection === 'asc' ? <RiArrowUpSLine onClick={() => handleSort('created_at')} /> : <RiArrowDownSLine onClick={() => handleSort('created_at')} />}
                            </th>
                            <th className={styles.columnRemove}></th>
                        </tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                        {documents.map((doc, index) => (
                            <tr className={styles.bodyRow} key={index}>
                                <td className={styles.typeCell}>{doc.type === 'file' ? <BsFiletypePdf className={styles.pdfIcon} size="1.5em" /> : doc.type === 'web' ? <BsLink45Deg className={styles.linkIcon} size="1.5em" /> : null}</td>
                                <td className={styles.titleCell} title={doc.title}>{doc.title.length > 80 ? `${doc.title.substring(0, 80)}...` : doc.title}</td>
                                <td className={styles.titleCell}>{moment(doc.created_at).format('YYYY-MM-DD')}</td>
                                <td className={styles.removeCell}>
                                    <Switch onChange={() => handleToggle(doc.id, doc.in_collection)} checked={documentStatus[doc.id] !== undefined ? documentStatus[doc.id] : doc.in_collection} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {(documents.length === 0) && (isLoading === false) && (
                    <div className={styles.emptyDiv}>
                        <div className={styles.emptyPostMessage}>
                            There's no documents yet.
                        </div>
                    </div>
                )}
                {(isLoading === true) && (<div className={styles.isLoading}><BeatLoader /></div>)}
            </div>
        </div>
    );
}

export default DocumentListButton;
