import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { API_URL } from '../../config';
import styles from './AnalyticsPage.module.css';
import { format } from 'date-fns';
import { getAmplifySessionHeaders } from '../../services/auth-service';
import { RiArrowRightUpLine, RiArrowRightDownLine } from "react-icons/ri";
import moment from 'moment';
import { BeatLoader } from 'react-spinners';

const TrendingList = ({ title, data, color }) => (
    <div className={styles.trendingList}>
        <h3 className={styles.trendingTitle}>{title}</h3>
        {data.map((row, index) => (
            <div className={styles.trendingContent}>
                <div className={`${styles.trendingName} ${styles[color]}`}>{row.name.length > 25 ? `${row.name.substring(0, 25)}...` : row.name}</div>
                <div className={styles.trendingValue}>{row.query_count}</div>
            </div>
        ))}
    </div>
);

const QueryCountChart = ({ data }) => (
    <ResponsiveContainer>
        <LineChart width="90%" height="90%" data={data}>
            <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={3} />
            <CartesianGrid stroke="#ccc" strokeDasharray="0 5" />
            <XAxis dataKey="date" tickFormatter={(tickItem) => format(new Date(tickItem), 'dd-MMM')} />
            <YAxis />
            <Tooltip />
        </LineChart>
    </ResponsiveContainer>
);


// Main component
const AnalyticsPage = () => {
    const [metrics, setMetrics] = useState({ number_of_queries: 0, percent_of_positive_feedback: 0.0, number_of_queries_trend: 0.0, positive_feedback_trend: 0.0 });
    const [trendingTags, setTrendingTags] = useState([]);
    const [dailyQueryCounts, setDailyQueryCounts] = useState([]);
    const [trendingUsers, setTrendingUsers] = useState([]);
    const [trendingCollections, setTrendingCollections] = useState([]);
    const [queryAnalysis, setQueryAnalysis] = useState("");
    const [days, setDays] = useState(7);
    const [queryData, setQueryData] = useState([]);
    const [queryPage, setQueryPage] = useState(1);
    const [queryLoading, setQueryLoading] = useState(true);
    const [queryError, setQueryError] = useState(false);
    const [queryHasMore, setQueryHasMore] = useState(false);
    const [summaryLoading, setSummaryLoading] = useState(true);
    const [metricsLoading, setMetricsLoading] = useState(true);
    const [graphLoading, setGraphLoading] = useState(true);
    const queryHasMoreRef = useRef(queryHasMore);

    const lastQueryElementRef = useRef();

    useEffect(() => {
        fetchAnalysis();
    }, []);

    const fetchAnalysis = async () => {
        setSummaryLoading(true);
        const headers = await getAmplifySessionHeaders();
        const resp = await fetch(`${API_URL}/analytics/orgs/analysis/`, {
            method: 'GET',
            headers: headers
        });
        const data = await resp.json();
        setQueryAnalysis(data.content)
        setSummaryLoading(false);
    }

    useEffect(() => {
        fetchQueries();
    }, [queryPage]);

    const fetchQueries = async () => {
        setQueryLoading(true);
        setQueryError(false);
        const headers = await getAmplifySessionHeaders();
        let cancel;
        axios({
            method: 'GET',
            url: `${API_URL}/analytics/orgs/queries/?page=${queryPage}`,
            cancelToken: new axios.CancelToken(c => cancel = c),
            headers: headers,  // Here we use the headers we just got
        }).then(res => {
            setQueryData(prevData => [...prevData, ...res.data.results]);
            const hasMore = res.data.next !== null;
            setQueryHasMore(hasMore);
            queryHasMoreRef.current = hasMore;  // Update the ref value here.
            setQueryLoading(false);
        }).catch(e => {
            if (axios.isCancel(e)) return;
            setQueryError(true);
        });

        return () => cancel();
    };

    const intersectionObserver = useRef(
        new IntersectionObserver(
            entries => {
                const first = entries[0];
                const currentHasMore = queryHasMoreRef.current;
                if (first.isIntersecting && currentHasMore) {
                    setQueryPage(prevPage => prevPage + 1);
                }
            },
            { threshold: 1 },
        )
    );

    useEffect(() => {
        const currentElement = lastQueryElementRef.current;
        const currentObserver = intersectionObserver.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [queryData]);

    const changeDays = (newDays) => {
        setDays(newDays);
        // Call your fetch data functions here. For example:
        fetchData(newDays);
    };

    useEffect(() => {
        fetchData(days);
    }, [days]); // re-run effect when days changes

    const fetchData = async (days) => {
        setMetricsLoading(true);
        setGraphLoading(true);

        const headers = await getAmplifySessionHeaders();
        const resp = await fetch(`${API_URL}/analytics/orgs/metrics/`, {
            method: 'POST',
            body: JSON.stringify({ days: days }),
            headers: headers
        });
        const data = await resp.json();

        setMetrics(data.metrics)
        setTrendingTags(data.trending_tags)
        setTrendingUsers(data.trending_users)
        setTrendingCollections(data.trending_collections)
        setMetricsLoading(false);

        axios.post(`${API_URL}/analytics/queries/daily_count/`, { days }, { headers: headers })
            .then(response => setDailyQueryCounts(response.data.queries_in_period))
            .catch(err => console.error(err));
        setGraphLoading(false);
    };

    const getIcon = (trend) => {
        if (trend > 0.0) {
            return <RiArrowRightUpLine size="19px" style={{ color: "green" }} />
        } else if (trend < 0.0) {
            return <RiArrowRightDownLine size="19px" style={{ color: "red" }} />
        } else {
            return ""
        }
    };

    return (
        <div className={styles.mainDiv}>
            <div className={styles.headerDiv}>
                <h1>Organization Analytics & Monitoring</h1>
            </div>
            <div className={styles.contentDiv}>
                <div className={styles.daySelect}>
                    <select
                        value={days}
                        onChange={(e) => changeDays(parseInt(e.target.value))}
                        style={{ marginBottom: '10px' }}
                    >
                        <option value={3}>3 Days</option>
                        <option value={7}>7 Days</option>
                        <option value={30}>30 Days</option>
                    </select>
                </div>
                <div className={styles.gridContainer}>
                    <div className={styles.gridItem + " " + styles.item1x1}>
                        {metricsLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <BeatLoader />
                        </div>
                            : <>
                                <p className={styles.metricTitle}>Total Queries</p>
                                <p className={styles.metricValue}>{metrics.number_of_queries.toLocaleString()}</p>
                                <p className={styles.metricTrend}>{getIcon(metrics.number_of_queries_trend)}{metrics.number_of_queries_trend.toFixed(1)}%</p>
                            </>}
                    </div>
                    <div className={styles.gridItem + " " + styles.item1x1}>
                        {metricsLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <BeatLoader />
                        </div>
                            : <>
                                <p className={styles.metricTitle}>Response Quality</p>
                                <p className={styles.metricValue}>{metrics.percent_of_positive_feedback.toFixed(0)}%</p>
                                <p className={styles.metricTrend}>{getIcon(metrics.positive_feedback_trend)}{metrics.positive_feedback_trend.toFixed(1)}%</p>
                            </>}
                    </div>
                    <div className={styles.gridItem + " " + styles.item1x3 + " " + styles.reactMarkdown}>
                        {summaryLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <BeatLoader />
                        </div>
                            :
                            <div dangerouslySetInnerHTML={{ __html: queryAnalysis }} />
                        }
                    </div>
                    <div className={styles.gridItem + " " + styles.item2x2}>
                        {graphLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <BeatLoader />
                        </div>
                            : <>
                                <h2>Daily Query Counts</h2>
                                <QueryCountChart data={dailyQueryCounts} />
                            </>}
                    </div>
                    <div className={styles.gridItem + " " + styles.item1x15}>
                        {metricsLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <BeatLoader />
                        </div>
                            :
                            <TrendingList title={'Trending Tags'} data={trendingTags} color={'green'} />
                        }
                    </div>
                    <div className={styles.gridItem + " " + styles.item1x15}>
                        {metricsLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <BeatLoader />
                        </div>
                            :
                            <TrendingList title={'Trending Users'} data={trendingUsers} color={'red'} />
                        }
                    </div>
                    <div className={styles.gridItem + " " + styles.item1x15}>
                        {metricsLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <BeatLoader />
                        </div>
                            :
                            <TrendingList title={'Trending Collections'} data={trendingCollections} color={'yellow'} />
                        }
                    </div>
                </div>
                <div className={styles.queryTable}>
                    <table className={styles.contentTable}>
                        <thead className={styles.tableHead}>
                            <tr className={styles.headRow}>
                                <th>User</th>
                                <th>Collection</th>
                                <th>Query</th>
                                <th>Response</th>
                                <th>Feedback</th>
                                <th>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {queryData.map((query, index) => (
                                <>
                                <tr className={styles.dateRow}>
                                    <td>{moment(query.created_at).format('YYYY-MM-DD hh:mm A')}</td>
                                </tr>
                                <tr className={styles.bodyRow} key={query.query_id} ref={index + 1 === queryData.length ? lastQueryElementRef : null}>
                                    <td>{query.user_email}</td>
                                    <td>{query.collection_name}</td>
                                    <td>{query.query}</td>
                                    <td>{query.response}</td>
                                    <td>{query.feedback.type}</td>
                                    <td>{query.feedback.message}</td>
                                </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                    {queryLoading && <div>Loading...</div>}
                    {queryError && <div>Error</div>}
                </div>
            </div>
        </div>
    );
};

export default AnalyticsPage;
