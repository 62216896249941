import { useState } from "react";
import { useParams } from 'react-router-dom';
import pdfIcon from "../../../../images/PDF-logo.png";
import xlsIcon from "../../../../images/XLS-logo.png";
import docIcon from "../../../../images/DOCX-logo.png";
import pptIcon from "../../../../images/PPTX-logo.png";
import { Input } from "../../../../components/input/input";
import { RiCloseLine } from 'react-icons/ri';
import sendButtonImg from "../../../../images/send-button.png";
import { uploadPdfToCollection } from '../../../../services/repository-service';
import { addWebDocument } from "../../../../services/api-service";
import { LoadingBars } from "../../../../images/svg/bars";
import { sendGAEvent } from '../../../../services/gaService';

export const AddContentModal = ({ onClose, onSubmit, onDocumentUpload }) => {
  const { collection_id } = useParams();
  const [url, setUrl] = useState('');
  const [errors, setErrors] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  async function handleAddUrlClick() {
    console.log(url);
    sendGAEvent('add_document', 'chat_page', 'web_document');
    setIsUploading(true);
    try {
      const data = await addWebDocument(collection_id, url);
      onDocumentUpload(data.summary);
      onClose();
    } catch (e) {
      console.error('Error submitting URL:', e.message);
      if (e.message === "402") {
        setErrors("You have reached the document upload limit for your membership level.");
      } else {
        setErrors("Error trying to submit new url to collection. Please try a different url.");
      }
    } finally {
      setIsUploading(false);
    }
  }


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      if (file.size > 15000000) {
        setErrors('File size too large! limit is 15 MB');
        return;
      }
      sendGAEvent('add_document', 'chat_page', 'pdf_document');
      setIsUploading(true);
      try {
        const response = await uploadPdfToCollection(collection_id, file);
        console.log(response);
        onDocumentUpload(response.summary); // Assuming the response contains the summary
        onClose();
      } catch (error) {
        console.error('Error uploading PDF:', error);
        if (error.message === "402") {
          setErrors("You have reached the document upload limit for your membership level.");
        } else {
          setErrors("Error uploading PDF");
        }
      } finally {
        setIsUploading(false);
      }
    }
  };

  const isValid = () => {
    return url.length > 0;
  }

  return (
    <div className="add-content-modal">
      <div className="add-content-modal__close-btn">
        <RiCloseLine onClick={onClose} size="45px" />
      </div>
      <div className="add-content-modal__header">
        <h5 className="">Add Content To Collection</h5>
      </div>
      {!isUploading && <>
        <div className="add-content-modal__upload-types-container">
          <p>Upload Documents</p>
          <div className="add-content-modal__upload-types">
            <label>
              <input
                type="file"
                accept=".pdf"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div className="image pdf-icon"></div>
            </label>

            <div className="image xls-icon"></div>
            <div className="image doc-icon"></div>
            <div className="image ppt-icon"></div>

          </div>
          {errors && <p className="text-danger">{errors}</p>}
        </div>
        <div>
          <p>Add Link</p>
          <div>
            <form onSubmit={handleAddUrlClick}>
              <Input
                onChange={(e) => setUrl(e.target.value)}
                value={url}
                placeholder="Paste URL to add to your collection"
                icon={<img src={sendButtonImg} alt="send-button" />}
              />
            </form>
          </div>
        </div>
      </>}
      {isUploading &&
        <div className="add-content-modal__upload-types-container" style={{ height: '100px' }}>
          <p>Uploading...</p>
          <LoadingBars width="40" height="40" fill="#bdeedd" />
        </div>
      }
    </div>
  )
}