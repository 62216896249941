import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { BiLogOut } from "react-icons/bi";
import { RiSettings3Line } from 'react-icons/ri';
import styles from "./SideBar.module.css";

Modal.setAppElement('#root');

const SideBar = ({ collection_id, collections, isOpen, setIsOpen }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && event.target.nodeName === 'DIV') {
        console.log(event.target.nodeName);
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  const handleFeedButtonClick = () => {
    setIsOpen(false);
    if (collection_id !== undefined) {
      navigate('/posts');
    }
  };

  const handleCollectionButtonClick = (collection) => {
    setIsOpen(false);
    navigate(`/posts/${collection.collection_id}`);
  };

  return (
    <div className={`${styles.sidePanel} ${isOpen ? styles.sidePanelOpen : styles.sidePanelClosed}`}>
      <div className={`${styles.sidePanelContent} ${isOpen ? styles.sidePanelContentOpen : styles.sidePanelContentClosed}`}>
        <div className={styles.collectionsList}>
          <button className={`${styles.feedButton} ${collection_id === undefined
            ? styles.selected
            : ""
            }`} onClick={handleFeedButtonClick}>
            My Feed
          </button>

          {collections.map((collection, index) => {
            return (
              <button
                className={`${styles.collectionBtn} ${styles.button} ${collection_id === collection.collection_id
                  ? styles.selected
                  : ""
                  }`}
                key={collection.collection_id}
                onClick={() => handleCollectionButtonClick(collection)}
              >
                <div className={styles.collectionContent}>
                  <span className={styles.collectionName}>{collection.name}</span>
                </div>
              </button>
            );
          })}
        </div>
        {isOpen && (<div className="side-panel__options">
          <div onClick={() => { navigate("/settings"); }} className="side-panel__option">
            <RiSettings3Line /> <p>Settings</p>
          </div>
          <div onClick={() => { navigate("/login"); }} className="side-panel__option">
            <BiLogOut /> <p>Log Out</p>
          </div>
        </div>)}
      </div>
    </div>
  );
}

export default SideBar;