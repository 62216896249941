import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { getAmplifySessionHeaders } from '../../services/auth-service';
import styles from './TemplateManager.module.css';
import { API_URL } from '../../config';

Modal.setAppElement('#root');

const TemplateManager = () => {
  const [templates, setTemplates] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [newTemplate, setNewTemplate] = useState({
    type: 'stream',
    name: "New Template",
    text: "Add prompt here",
    response: "{response}",
    public: true,
    model: "gpt-3.5-turbo-16k",
    responseobjects_set:	[]
  });

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    const headers = await getAmplifySessionHeaders();
    fetch(`${API_URL}/templates/`, {
      method: 'GET',
      headers: headers
    })
      .then(res => res.json())
      .then(data => setTemplates(data))
      .catch(err => console.error(err));
  };

  async function openModal(template) {
    try {
      const headers = await getAmplifySessionHeaders();
      const response = await fetch(`${API_URL}/templates/${template.template_id}`, {
        method: 'GET',
        headers: headers
      });

      const data = await response.json();
      setCurrentTemplate(data);
      setIsOpen(true);
    } catch (err) {
      console.error(err);
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeAddModal() {
    setAddModalIsOpen(false);
  }

  function handleAddChange(e) {
    let value = e.target.value;
    if (e.target.name === 'public') {
      value = e.target.checked;
    }
    setNewTemplate({ ...newTemplate, [e.target.name]: value });
  }

  function handleChange(e) {
    if (e.target.name === 'structure') {
      setCurrentTemplate({
        ...currentTemplate,
        responseobjects_set: [{
          ...(currentTemplate.responseobjects_set && currentTemplate.responseobjects_set[0] ? currentTemplate.responseobjects_set[0] : {}),
          structure: JSON.parse(e.target.value)
        }]
      });
    } else {
      setCurrentTemplate({ ...currentTemplate, [e.target.name]: e.target.value });
    }
  }

  async function saveChanges() {
    const headers = await getAmplifySessionHeaders();
    fetch(`${API_URL}/templates/${currentTemplate.template_id}`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(currentTemplate),
    })
      .then(res => res.json())
      .then(data => {
        setIsOpen(false);
        setTemplates(templates.map(t => t.template_id === currentTemplate.template_id ? data : t));
      })
      .catch(err => console.error(err));
  }

  async function saveNewTemplate() {
    const headers = await getAmplifySessionHeaders();
    fetch(`${API_URL}/templates/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(newTemplate),
    })
      .then(res => res.json())
      .then(data => {
        setAddModalIsOpen(false);
        setTemplates([...templates, data]);
      })
      .catch(err => console.error(err));
  }

  return (
    <div>
      <h1>Template Manager</h1>
      <button onClick={() => setAddModalIsOpen(true)}>Add Template</button>
      <table className={styles.templateTable}>
        <tbody>
          {templates.map(t => (
            <tr className={styles.templateRow} key={t.template_id}>
              <td>{t.name}</td>
              <td><button className={styles.editButton} onClick={() => openModal(t)}>Edit</button></td>
            </tr>
          ))}
        </tbody>
      </table>
      {currentTemplate && (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Edit Template" style={{ overflow: 'scroll' }} className={styles.Modal}>
          <h2>{currentTemplate.name}</h2>
          <form className={styles.form}>
            <div className={styles.row}>
              <label className={styles.label}>Template ID:</label>
              <input type="text" name="template_id" value={currentTemplate.template_id} onChange={handleChange} className={styles.input} />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>Name:</label>
              <input type="text" name="name" value={currentTemplate.name} onChange={handleChange} className={styles.input} />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>Text:</label>
              <textarea name="text" value={currentTemplate.text} onChange={handleChange} className={styles.largeInput} />
            </div>
            {currentTemplate.type === 'struct' && (
              <div className={styles.row}>
                <label className={styles.label}>Response:</label>
                <textarea name="response" value={currentTemplate.response} onChange={handleChange} className={styles.largeInput} />
              </div>
            )}
            <div className={styles.row}>
              <label className={styles.label}>Public:</label>
              <input type="checkbox" name="public" checked={currentTemplate.public} onChange={handleChange} className={styles.input} />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>Model:</label>
              <input type="text" name="model" value={currentTemplate.model} onChange={handleChange} className={styles.input} />
            </div>
            {currentTemplate.type === 'struct' && (
              <div className={styles.row}>
                <label className={styles.label}>Structure:</label>
                <textarea name="structure" value={JSON.stringify(currentTemplate.responseobjects_set[0].structure, null, 2)} onChange={handleChange} className={styles.largeInput} />
              </div>
            )}
          </form>
          <button className={styles.saveButton} onClick={saveChanges}>Save</button>
        </Modal>

      )}
      <Modal isOpen={addModalIsOpen} onRequestClose={closeAddModal} contentLabel="New Template" className={styles.Modal}>
        <h2>New Template</h2>
        <form className={styles.form}>
          <div className={styles.row}>
            <label className={styles.label}>Type:</label>
            <select name="type" value={newTemplate.type} onChange={handleAddChange} className={styles.input}>
              <option value="stream">Stream</option>
              <option value="struct">Struct</option>
            </select>
          </div>
          <div className={styles.row}>
            <label className={styles.label}>Name:</label>
            <input type="text" name="name" value={newTemplate.name || ''} onChange={handleAddChange} className={styles.input} />
          </div>
          <div className={styles.row}>
            <label className={styles.label}>Text:</label>
            <textarea name="text" value={newTemplate.text || ''} onChange={handleAddChange} className={styles.largeInput} />
          </div>
          {newTemplate.type === 'struct' && (
            <div className={styles.row}>
              <label className={styles.label}>Response:</label>
              <textarea name="response" value={newTemplate.response || ''} onChange={handleAddChange} className={styles.largeInput} />
            </div>
          )}
          <div className={styles.row}>
            <label className={styles.label}>Public:</label>
            <input type="checkbox" name="public" checked={newTemplate.public || false} onChange={handleAddChange} className={styles.input} />
          </div>
          <div className={styles.row}>
            <label className={styles.label}>Model:</label>
            <input type="text" name="model" value={newTemplate.model || ''} onChange={handleAddChange} className={styles.input} />
          </div>
          {newTemplate.type === 'struct' && (
            <div className={styles.row}>
              <label className={styles.label}>Structure:</label>
              <textarea name="structure" value={newTemplate.structure || ''} onChange={handleAddChange} className={styles.largeInput} />
            </div>
          )}
        </form>
        <button className={styles.saveButton} onClick={saveNewTemplate}>Save</button>
      </Modal>
    </div>
  );
}

export default TemplateManager;
