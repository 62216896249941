import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createCollection } from '../../../../services/api-service';
import { sendGAEvent } from '../../../../services/gaService';
//import "./NewCollection.css";

const NewCollection = ({ collections }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  async function handleAddCollectionClick() {
    try {
      sendGAEvent('add_collection', 'collectionPage', 'add_collection');
      const newCollection = await createCollection("New Collection");
      navigate(`/collections/${newCollection.collection_id}`);
    } catch (error) {
      console.error("error creating a new collection: ", error);
      setErrorMessage("Sorry, there was a problem creating a new Collection. Please try again.")
    }
  }

  return (
    <div className="how-it-works">
      <div className="how-it-works-content-container">
        <div className="content-wrapper">
          <div className="how-it-works-wrapper" style={{ marginTop: '2rem', alignSelf: 'center' }}>
            <div className="how-it-works__header text-center">
              {collections && (<>
                <div className="how-it-works__blurb">
                  <div className="how-it-works-text-container">
                    <h1 className="how-it-works-title">
                      Create Your Expert Collection
                    </h1>
                    <ul className="how-it-works-list">
                      <li>Seamlessly curate a collection of article links, PDFs, and other documents</li>
                      <li>Unlock instant, AI-generated summaries and dive deeper with questions</li>
                      <li>Amplify your knowledge by sharing with your network and collaborating</li>
                    </ul>
                    <p>
                      <strong>Number of Collections:</strong> {collections.length}
                    </p>
                  </div>
                  <div className="add-collection-button-container">
                    <button className="add-collection-button-alt" onClick={handleAddCollectionClick}>
                      ADD COLLECTION
                    </button>
                  </div>
                </div>
                <div className="instructions-wrapper">
                  <div className="instruction">
                    <div className="instruction-icon-1"></div>
                    <p style={{ maxWidth: '200px', textAlign: 'center' }}>
                      Click "Add Collection" to create a knowledge base
                    </p>
                  </div>
                  <div className="instruction">
                    <div className="instruction-icon-2"></div>
                    <p style={{ maxWidth: '200px', textAlign: 'center' }}>
                      Upload your documents - pdfs, links, word, etc.
                    </p>
                  </div>
                  <div className="instruction">
                    <div className="instruction-icon-3"></div>
                    <p style={{ maxWidth: '200px', textAlign: 'center' }}>
                      Improve your workflows with your collection
                    </p>
                  </div>
                </div>
              </>)}
              <div className="how-it-works__error text-danger">
                {errorMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCollection;