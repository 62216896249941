import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import styles from './SettingsPage.module.css';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    let errorMessage = "";
    if (password.length < 8) {
      errorMessage = "Password should be at least 8 characters long";
    } else if (!/[A-Z]/.test(password)) {
      errorMessage = "Password should contain at least one uppercase letter";
    } else if (!/[a-z]/.test(password)) {
      errorMessage = "Password should contain at least one lowercase letter";
    } else if (!/[0-9]/.test(password)) {
      errorMessage = "Password should contain at least one number";
    } else if (!/[^A-Za-z0-9]/.test(password)) {
      errorMessage = "Password should contain at least one special character";
    }
    return errorMessage;
  };

  const handleResetPassword = async () => {
    try {
      await Auth.forgotPassword(email);
      setIsCodeSent(true);
    } catch (error) {
      console.error('Error resetting password:', error);
    }
  };

  const handleConfirmResetPassword = async () => {
    const passwordError = validatePassword(password);
    if (passwordError) {
      setError(passwordError);
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(email, confirmationCode, password);
      alert('Password reset successfully');
      navigate('/login');
    } catch (error) {
      console.error('Error confirming password reset:', error);
    }
  };

  return (
    <div className={styles.container2}>
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>Reset Password</h2>
        {!isCodeSent ? (
          <>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={styles.input}
            />
            <button onClick={handleResetPassword} className={styles.button}>Send Confirmation Code</button>
          </>
        ) : (
          <>
            <input
              type="text"
              placeholder="Enter Confirmation Code"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              className={styles.input}
            />
            <input
              type="password"
              placeholder="Enter New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={styles.input}
            />
            <button onClick={handleConfirmResetPassword} className={styles.button}>Confirm Reset Password</button>
          </>
        )}
        {error && (
          <div className={styles.authErrorMessage}>
            {error}
          </div>
        )}
      </section>
    </div>
  );
};

export default PasswordReset;