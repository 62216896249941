// The component renders a chat page UI that allows users to add collections and URLs to the page, and display messages.

// Importing necessary modules and files
import React, { useEffect, useState, useRef } from 'react'; // Importing React library modules, useState and useEffect hooks, and useRef hook
import "./chat-page.css";
import sendButtonImg from '../../images/send-button.png'; // Importing send button image
import { createCollection, getCollectionContext, getCollectionContextStream, getCollections, getCollectionDocs, inputPrompt, addUrlToCollection, editCollection, deleteCollection } from '../../services/repository-service'; // Importing functions from repository-service file
import { ThreeDots } from '../../images/svg/threedots';
import Modal from 'react-modal';
import { CollectionsSidePanel } from './components/collections-side-panel/collections-side-panel';
import { MobileHeaderBar } from './components/mobile-header-bar/mobile-header-bar';
import { LoadingBars } from '../../images/svg/bars';
import { AddContentModal } from './components/add-content-modal/add-content-modal';
import { Input } from '../../components/input/input';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiAnnotation } from 'react-icons/hi';
import { HiOutlineCode } from 'react-icons/hi';
import { HiOutlineDocument } from 'react-icons/hi';
import { AddPromptModal } from "./components/add-prompt-modal/add-prompt-modal";
import { ManageDocumentModal } from './components/manage-document-modal/manage-document-modal';
import { sendGAEvent } from '../../services/gaService';

Modal.setAppElement('#root');

export const ChatPage = () => {

  // State hooks for managing messages, fetching status, current state, and input text. They allow the component to keep track of the state of the chat page and update the UI accordingly.
  const [messages, setMessages] = useState([]); // This state hook is used to store an array of chat messages that are displayed on the chat page. Whenever a new message is sent or received, this state is updated to reflect the latest messages in the chat.
  const [isFetching, setIsFetching] = useState(false); // This state hook is used to track whether a fetch request is currently in progress. This is important to prevent multiple simultaneous fetch requests, which could lead to unpredictable behavior.
  const [isFetchingCollection, setIsFetchingCollection] = useState(false);
  const [currentState, setCurrentState] = useState(1); // tracks the current state of the chat page (e.g. whether the user is viewing the chat history or inputting a new message)
  const [inputText, setInputText] = useState(""); // stores the user's input text (e.g. the message they are typing)
  const [inputUrl, setInputUrl] = useState(""); // stores the user's input URL (e.g. the URL they are providing)
  const chatBoxRef = useRef(null); // provides a reference to the chat box element (used for scrolling to the bottom of the chat)
  const [collections, setCollections] = useState([]); // stores an array of the user's collections
  const [selectedCollection, setSelectedCollection] = useState(null); // tracks which collection is currently selected by the user
  const [documents, setDocuments] = useState([]); // stores an array of documents within the currently selected collection
  const [documentUrl, setDocumentUrl] = useState(''); // stores url value for adding a new url to selectedCollection
  const [selectedCollectionName, setSelectedCollectionName] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [collectionToDelete, setCollectionToDelete] = useState(null);
  const [isSidebarOpen, setSideBarOpen] = useState(false);
  const [showAddContentModal, setShowAddContentModal] = useState(false);
  const [showAddPromptModal, setShowAddPromptModal] = useState(false);
  const [showManageDocumentModal, setShowManageDocumentModal] = useState(false);
  const [documentsCount, setDocumentsCount] = useState(0);
  const [userScrolledUp, setUserScrolledUp] = useState(false);

  // *** INITIAL RENDERING OF COMPONENT *** //

  // runs the fetchCollections function to fetch the collections from the repository service.
  useEffect(() => {
    fetchCollections();
  }, []);

  // ensures that the chat box scrolls to the bottom whenever a new message is added to the messages state variable or the currentState changes to the chat state.
  useEffect(() => {
    if (currentState === 2 && chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, currentState]);

  // ***  ADD NEW COLLECTION *** //

  const handleNewCollectionClick = () => {
    setSelectedCollection(null); // Set the selected collection to null
    setCurrentState(1);
  };

  // state of the chat column chnages to "create new collection" which allows the user to name the collection and enter their first URL 
  async function handleAddCollectionClick() {
    try {
      sendGAEvent('add_collection', 'chat_page', 'add_collection');
      const newCollection = await createCollection("New Collection");
      setCollections([...collections, newCollection]);
      // Call setIntroMessage with isNewCollection set to true
      setInstructiveIntroMessage();
      setSelectedCollectionName(newCollection.name);
      setSelectedCollection(newCollection);
      setCurrentState(2);
    } catch (error) {
      console.error("Error creating empty collection:", error);
    }
  }

  // Function for handling URL submit
  const handleUrlSubmit = async (e, url) => {
    if (e != null) {
      e.preventDefault();
    }
    if (!isFetching) { // if not currently fetching data
      setIsFetching(true); // set fetching status to true
      // Call API to Create New Collection
      try {
        sendGAEvent('add_document', 'chat_page', 'web_document');
        setErrorMessage('');
        setMessages([]);
        setInputUrl('');
        const data = await createCollection("New Collection", url) // create a new collection 
        setMessages((prev) =>
          [...prev, { text: <div>Summarizing URL: <h5>{inputUrl}</h5></div>, align: 'right' },
          { text: formatMessage(data.summary), align: 'left' }]);
        setIsFetching(false);
        // set current state to chat
        setCurrentState(2);
        const newCollection = { name: "New Collection", collection_id: data.collection_id };
        setCollections((prev) => [...prev, newCollection]);
        setSelectedCollection(newCollection);
        setSelectedCollectionName(newCollection.name);
      } catch (e) {
        setErrorMessage('Error trying to submit url. Please try another Url');
      } finally {
        setIsFetching(false);
      }
    }
  }

  const onDocumentUpload = (summary) => {
    setMessages((prev) =>
      [...prev, { text: formatMessage(summary), align: 'left' }]);
  }


  // ***  LOAD COLLECTIONS *** //

  // All Collections - Function to fetch all collections from repository service
  const fetchCollections = async () => {
    setIsFetching(true);
    try {
      const fetchedCollections = await getCollections();
      console.log('Fetched collections:', fetchedCollections);
      setCollections(fetchedCollections);
    } catch (error) {
      console.error('Error fetching collections:', error);
    } finally {
      setIsFetching(false);
    }
  };

  // when changing collections it should change the state to a new collection then do all the fetching


  // One Collection - Function to fetch documents in a collection from repository service
  async function fetchCollectionDocs(collection) {
    console.log("fetchCollectionDocs - collection", collection);
    setCurrentState(2);
    setIsFetchingCollection(true);
    setMessages([]);
    // Update the selected collection in state
    setSelectedCollection(collection);
    setSelectedCollectionName(collection.name);
    setSideBarOpen(false);
    let currentResponse = '';
    try {
      const response = await getCollectionDocs(collection.collection_id);
      if (response.length > 0) {
        const messageHandler = async (partialResponse) => {
          if (!currentResponse) {
            setMessages((prev) => [
              ...prev,
              { text: "", align: "left", type: "bot" },
            ]);
          }
          currentResponse += partialResponse;
          await renderWordByWord(currentResponse);
        }
        await getCollectionContextStream(collection.collection_id, messageHandler);
      } else {
        await setInstructiveIntroMessage(null);
      }
      // Update the documents state with the fetched documents
      setDocuments(response);
    } catch (e) {
      console.log(e);
      console.log("error");
      setMessages([]);
      renderErrorMessage('Error fetching collection')
    } finally {
      setIsFetchingCollection(false);
    }
  }


  async function setInstructiveIntroMessage() {
    const delay = 50;
    let message = "";

    const formatAndSplitMessage = (msg) => {
      return msg.replace(/<br>/g, "\n").split(" ");
    };

    const words = formatAndSplitMessage(
      "Welcome to your New Collection! A few things to get you started:\n\n- Click \"+\" to add articles and documents to this knowledge base.\n- Click on the \"New Collection\" text in the header to change the title of this collection."
    );

    for (const [index, word] of words.entries()) {
      message += (index === 0 ? "" : " ") + word;

      await new Promise((resolve) => setTimeout(resolve, delay));

      setMessages([
        {
          text: (
            <div>
              <b>{message.trim()}</b>
            </div>
          ),
          align: "left",
        },
      ]);
    }
  }


  async function renderWordByWord(response) {
    const formattedResponse = response.replace(/<br>/g, '\n');
    const words = formattedResponse.split(' ');
    const delay = 50;

    let message = '';
    let lastWord = '';

    for (const [index, word] of words.entries()) {
      if (lastWord.endsWith('\n') || word.startsWith('\n')) {
        message += word;
      } else {
        message += (index === 0 ? '' : ' ') + word;
      }

      lastWord = word;
      // await new Promise((resolve) => setTimeout(resolve, delay));
    }

    setMessages((prev) => {
      const newMessages = [...prev];
      newMessages[newMessages.length - 1] = {
        text: message.trim(),
        align: 'left',
        type: 'bot',
      };
      return newMessages;
    });

    scrollToBottom(); // Move scrollToBottom() call here
    setIsFetching(false);
  }
  // ***  ADD URL TO EXISTING COLLECTION *** //

  async function handleAddUrlClick(url) {
    setIsFetching(true);
    setDocumentUrl('');
    try {
      sendGAEvent('add_document', 'chat_page', 'web_document');
      const data = await addUrlToCollection(selectedCollection.collection_id, url);
      const successMessage = {
        text: <div>
          <strong>successfully added {url}</strong>
          <p>{formatMessage(data.summary)}</p>
        </div>,
        align: 'left'
      }
      setMessages((prev) => [...prev, successMessage]);
    } catch (e) {
      const errorMessage = {
        text: <span className='text-danger'>Error trying to submit new url to collection. Please try a different url.</span>,
        align: 'left'
      }
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsFetching(false);
    }
  }

  // ***  CHAT RELATED FUNCTIONALITY *** //

  // Update handleChatSubmit function to pass collectionId to inputPrompt function
  const scrollToBottom = () => {
    if (chatBoxRef.current && !userScrolledUp) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const handleScroll = () => {
    if (chatBoxRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatBoxRef.current;
      setUserScrolledUp(scrollTop + clientHeight < scrollHeight);
    }
  };

  async function handleChatSubmit(event, collectionId) {
    event.preventDefault();
    if (inputText.trim() === "") return;

    sendGAEvent('submit_query', 'chat_page', 'form_submission');

    const userInput = inputText;
    setInputText("");
    setMessages((prev) => [
      ...prev,
      { text: userInput, align: "right", type: "user" },
    ]);

    setIsFetching(true);

    let currentResponse = '';



    const messageHandler = async (partialResponse) => {
      if (!currentResponse) {
        setMessages((prev) => [
          ...prev,
          { text: "", align: "left", type: "bot" },
        ]);
      }
      currentResponse += partialResponse;
      await renderWordByWord(currentResponse);
    };

    await inputPrompt(collectionId, userInput, true, messageHandler);
  }

  function formatMessage(message) {
    return <span>
      {message.split(/\n/).map((line, i) => <React.Fragment key={i}>{line}<br /></React.Fragment>)}
    </span>
  }

  function renderErrorMessage(message) {
    const errorMessage = {
      text: <span className='text-danger'>{message}</span>,
      align: 'left'
    }
    setMessages((prev) => [...prev, errorMessage]);
  }

  async function submitCollectionName() {
    // don't call api if the name hasn't changed
    if (selectedCollectionName === selectedCollection.name) {
      return;
    }
    try {
      let newName = selectedCollectionName;
      console.log(`colleciton name is ${newName}`);
      if (selectedCollectionName === '') {
        newName = "New Collection";
      }
      const res = await editCollection(selectedCollection.collection_id, newName);
      // change existing collection name
      setCollections((prev) => {
        let newCollections = prev.filter(t => t.collection_id !== res.collection_id);
        newCollections.push(res);
        return newCollections;
      });
      setSelectedCollection({ ...selectedCollection, name: res.name });
      setSelectedCollectionName(res.name);
    } catch (e) {
      console.log("error when changing name");
    }
  }

  const handleDeleteCollection = (collection) => {
    setSideBarOpen(false);
    setShowDeleteModal(true);
    setCollectionToDelete(collection);
  }

  const confirmDeleteCollection = async () => {
    try {
      await deleteCollection(collectionToDelete.collection_id);
      setShowDeleteModal(false);
      setCollections(prev => prev.filter(t => t.collection_id !== collectionToDelete.collection_id));
      if (selectedCollection && selectedCollection.collection_id === collectionToDelete.collection_id) {
        setCurrentState(1);
      }
      setCollectionToDelete(null);
    } catch (e) {
      console.log(e);
      console.log("error deleting collection");
    }
  }

  const handleUpdateCollectionsOrder = (updatedCollections) => {
    // Update the order of the collections in the state or in the backend
    // For example, you could update the state with the new order:
    setCollections(updatedCollections);
  };

  return (
    // Chat page component with two states. 
    // In state one, the user can input a URL to add to their collection, while in state two, the user can chat and ask questions about the articles in the collection. The component also includes a side panel with a button to add a new collection and a list of existing collections that the user can click on to fetch the collection's documents.
    <div className="page-container">
      <div className="chat-page">
        <Modal isOpen={showDeleteModal}>
          <div
            className='confirmation-modal shadow'
          >
            <span>Are you sure you want to delete? {collectionToDelete?.name}</span>
            <div className='delete-row'>
              <button className='shadow delete-button' onClick={confirmDeleteCollection}>Delete</button>
              <button className="shadow" onClick={() => setShowDeleteModal(false)}>Cancel</button>
            </div>
          </div>
        </Modal>

        <Modal isOpen={showAddContentModal}>
          <AddContentModal
            onSubmit={currentState === 2 ? handleAddUrlClick : handleUrlSubmit}
            collectionId={selectedCollection ? selectedCollection.collection_id : null}
            onClose={() => setShowAddContentModal(false)}
            onDocumentUpload={onDocumentUpload}
          />
        </Modal>

        {/* Render the AddPromptModal inside a Modal component */}
        <Modal isOpen={showAddPromptModal}>
          <AddPromptModal
            collectionId={selectedCollection ? selectedCollection.collection_id : null}
            onClose={() => setShowAddPromptModal(false)}
          />
        </Modal>

        <Modal isOpen={showManageDocumentModal}>
          <ManageDocumentModal
            collectionId={selectedCollection ? selectedCollection.collection_id : null}
            onClose={() => setShowManageDocumentModal(false)}
          />
        </Modal>

        <CollectionsSidePanel
          collections={collections}
          handleDeleteCollection={handleDeleteCollection}
          selectedCollection={selectedCollection}
          handleCollectionClick={fetchCollectionDocs}
          isOpen={isSidebarOpen}
          handleClose={() => setSideBarOpen(false)}
          handleNewCollectionClick={handleNewCollectionClick}
          setCollections={setCollections}
          handleUpdateCollectionsOrder={handleUpdateCollectionsOrder} // Pass the function here
        />
        {/* There are two states to the chat feature:
                        State 1: allows the user to input a URL to add to their collection.
                        State 2: shows the chat box where users can send messages and ask questions about the added URL. */}
        <div className="chat-column">
          <div className="fixed-header">
            <div className="header-content">
              <div className="header-left">
                {currentState === 2 && <input onChange={(e) => { setSelectedCollectionName(e.target.value) }} onBlur={submitCollectionName} className="header-title" value={selectedCollectionName ?? 'New Collection'} />}
              </div>
            </div>
            {currentState === 2 &&
              <div className="header-right">
                <HiOutlineCode onClick={() => setShowAddPromptModal(true)} color="black" size="25px" style={{ marginRight: '15px' }} />
                <HiOutlineDocument onClick={() => setShowManageDocumentModal(true)} style={{ marginRight: '15px' }} color="black" size="25px" />
                <AiOutlinePlus onClick={() => setShowAddContentModal(true)} color="black" size="25px" />
              </div>}
          </div>

          <MobileHeaderBar
            handleShowAddContentModal={() => setShowAddContentModal(true)}
            handleCollectionNameChange={setSelectedCollectionName}
            handleSubmitCollectionName={submitCollectionName}
            collectionName={currentState === 2 ? selectedCollectionName : "Create"}
            handleOpenSideBar={() => setSideBarOpen(true)}
            currentState={currentState}
            handleShowAddPromptModal={() => setShowAddPromptModal(true)}
            handleShowManageDocumentModal={() => setShowManageDocumentModal(true)}
          />

          {/* State 1: add URL to new collection */}
          {currentState === 1 && (
            <div className="how-it-works">

              <div className="how-it-works-content-container">
                <div className="content-wrapper">
                  <div
                    className="how-it-works-wrapper"
                    style={{ marginTop: '2rem', alignSelf: 'center' }}
                  >
                    {/* Add inline style here */}
                    <div className="how-it-works__header text-center">
                      {!isFetching && collections && (<>
                        <div className="how-it-works__blurb">
                          <div className="how-it-works-text-container">
                            <h1 className="how-it-works-title">
                              Create Your Expert Collection
                            </h1>
                            <ul className="how-it-works-list">
                              <li>
                                Seamlessly curate a collection of article links, PDFs, and
                                other documents
                              </li>
                              <li>
                                Unlock instant, AI-generated summaries and dive deeper
                                with questions
                              </li>
                              <li>
                                Amplify your knowledge by sharing with your network and
                                collaborating
                              </li>
                            </ul>
                            <p>
                              <strong>Number of Collections:</strong> {collections.length}
                            </p>
                          </div>
                          <div className="add-collection-button-container">
                            <button
                              className="add-collection-button-alt"
                              onClick={handleAddCollectionClick}
                            >
                              ADD COLLECTION
                            </button>
                          </div>

                        </div>
                        <div className="instructions-wrapper">
                          <div className="instruction">
                            <div className="instruction-icon-1"></div>
                            <p style={{ maxWidth: '200px', textAlign: 'center' }}>
                              Click "Add Collection" to create a knowledge base
                            </p>
                          </div>
                          <div className="instruction">
                            <div className="instruction-icon-2"></div>
                            <p style={{ maxWidth: '200px', textAlign: 'center' }}>
                              Upload your documents - pdfs, links, word, etc.
                            </p>
                          </div>
                          <div className="instruction">
                            <div className="instruction-icon-3"></div>
                            <p style={{ maxWidth: '200px', textAlign: 'center' }}>
                              Improve your workflows with your collection
                            </p>
                          </div>
                        </div>
                      </>
                      )}
                      {isFetching && <LoadingBars width="60" height="60" fill="#bdeedd" />}
                      <div className="how-it-works__error text-danger">
                        {errorMessage}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* State 2: chat */}
          {currentState === 2 && (
            <div className="chat">
              {/* Chat box */}
              <div ref={chatBoxRef} className="chat-box" onScroll={handleScroll}>
                {!isFetchingCollection && (
                  <>
                    {messages.map((message, index) => (
                      <div
                        key={index}
                        className={`message ${message.align} ${message.align === 'left' ? 'bg-lightblue' : 'bg-lightgray'
                          }`}
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {message.text}
                      </div>
                    ))}
                    {isFetching && (
                      <div className="message left loading-message">
                        <span>
                          {' '}
                          <ThreeDots
                            width="25"
                            height="25"
                            padding-top="5px"
                            fill="#00b894"
                          />{' '}
                        </span>
                      </div>
                    )}
                  </>
                )}
                {isFetchingCollection && (
                  <div className="message left loading-message">
                    <span>
                      Loading collection summary{' '}
                      <ThreeDots width="25" height="25" fill="#00b894" />{' '}
                    </span>
                  </div>
                )}
              </div>

              {/* Container for chat input field and submit button */}
              <div className="chat-input-container">
                {/* Form to handle chat submission */}
                <form onSubmit={(event) => handleChatSubmit(event, selectedCollection.collection_id)}>
                  <Input
                    onChange={(e) => setInputText(e.target.value)}
                    type="text"
                    value={inputText}
                    placeholder="Ask a question about this collection ..."
                    icon={<img src={sendButtonImg} alt="send-button" />}
                  />
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
