import React from 'react';
import styles from "./ConfirmationModal.module.css";

const ConfirmationModal = ({ setShow, message, onConfirm }) => {
  // function to close the modal
  const handleClose = () => {
    setShow(false);
  };

  // function to confirm the action
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <div className={`${styles.confirmationModal} ${styles.shadow}`}>
      <div className={styles.boxInModal}>
        <div className={styles.confirmationMessage}>{message}</div>
        <div className={styles.confirmationRow}>
          <button className={styles.yesButton} onClick={handleConfirm}>Yes</button>
          <button className={styles.cancelButton} onClick={handleClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;