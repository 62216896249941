import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { createUser } from '../../services/api-service';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from '../../services/auth-service';
import styles from "./SignUp.module.css";

const SignUp = () => {
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isSignedUp, setIsSignedUp] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    let errorMessage = "";
    if (password.length < 8) {
      errorMessage = "Password should be at least 8 characters long";
    } else if (!/[A-Z]/.test(password)) {
      errorMessage = "Password should contain at least one uppercase letter";
    } else if (!/[a-z]/.test(password)) {
      errorMessage = "Password should contain at least one lowercase letter";
    } else if (!/[0-9]/.test(password)) {
      errorMessage = "Password should contain at least one number";
    } else if (!/[^A-Za-z0-9]/.test(password)) {
      errorMessage = "Password should contain at least one special character";
    }
    return errorMessage;
  };

  const signUp = async (e) => {
    e.preventDefault();
    const passwordError = validatePassword(password);
    if (passwordError) {
      setError(passwordError);
      return;
    }
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: givenName,
          family_name: familyName,
        },
      });
      console.log('User signed up:', user);
      setIsSignedUp(true);
    } catch (error) {
      console.log('Error signing up:', error);
      setError(error.message);
    }
  };

  const confirmSignUp = async (e) => {
    e.preventDefault();
    try {
      await Auth.confirmSignUp(email, confirmationCode);
      console.log('User confirmed sign up');

      // Get user attributes after confirming sign up
      let user = await Auth.signIn(email, password);
      console.log('User signed in:', user);
      console.log('User name:', user.username);
      createUser(user.username, email, givenName, familyName);
      await Auth.signOut();
      user = await Auth.signIn(email, password);
      const signInUserSession = user.signInUserSession;
      const idToken = signInUserSession.idToken;
      const refreshToken = signInUserSession.refreshToken;
      const token = idToken.jwtToken;
      const expiry = idToken.getExpiration();
      setAuthToken(token, refreshToken.token, expiry);
      navigate('/collections');
    } catch (error) {
      console.log('Error confirming sign up:', error);
      setError(error.message);
    }
  };

  return (
    <div className={styles.signupPage}>
      <header className={styles.authHeader}>
        <div className={styles.authHeaderInner}>
          <div className={styles.logoContainer}></div>
          <button className={styles.authButton} onClick={() => navigate("/login")}>
            Sign In
          </button>
        </div>
      </header>

      <div className={styles.authLeft}>
        <div className={styles.authTextContainer}>
          <h1>
            <span className={styles.authGreenText}>AI-Powered</span> Tools to Empower Credit Unions
          </h1>
          {!isSignedUp && <>
            <div className={styles.authTextGetAccess}>
              <h2>For Credit Unions, By Credit Unions</h2>
              <p>Register to get free access for a limited time only</p>
            </div>
            <div className={styles.authInputContainer}>
              <form>
                <div className={styles.inputRowContainer}>
                  <input value={givenName} onChange={(e) => setGivenName(e.target.value)} type="text" placeholder="Given Name" className={styles.authInput} />
                  <input value={familyName} onChange={(e) => setFamilyName(e.target.value)} type="text" placeholder="Family Name" className={styles.authInput} />
                </div>
                <div className={styles.inputRowContainer}>
                  <input onChange={(e) => setEmail(e.target.value)} value={email} type="text" placeholder="Email" className={`${styles.authInput} ${styles.authEmailInput}`} />
                </div>
                <div className={styles.inputRowContainer}>
                  <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" placeholder="Password" className={`${styles.authInput} ${styles.authEmailInput}`} />
                </div>
                {error && (
                  <div className={styles.authErrorMessage}>
                    {error}
                  </div>
                )}
                <div className={styles.authInputRow}>
                  <button onClick={signUp} className={styles.authButtonWaitlist} type="submit">
                    Sign Up
                  </button>
                </div>
                <div className={styles.ppRow}>
                  <span>By clicking "Sign Up", you agree to our </span>
                  <a href="https://senso-static-files-us.s3.amazonaws.com/Senso.ai+Terms+of+Use+v1.0+July+'23.pdf" target="_blank" rel="noreferrer">Terms</a>
                  <span> and acknowledge our </span>
                  <a href="https://senso-static-files-us.s3.amazonaws.com/Senso.ai+Privacy+Policy+v1.0+July+'23.pdf" target="_blank" rel="noreferrer">Privacy Policy</a>
                </div>
              </form>
            </div>
          </>}
          {
            isSignedUp && <>
              <div>
                <div className={styles.authTextGetAccess}>
                  <h2>Email Confirmation</h2>
                  <p>Check your email to get your code</p>
                </div>
                <div className={styles.authInputContainer}>
                  <form>
                    <div className={styles.inputRowContainer}>
                      <input
                        type="text"
                        className={`${styles.authInput} ${styles.authEmailInput}`}
                        placeholder="Confirmation Code"
                        value={confirmationCode}
                        onChange={(e) => setConfirmationCode(e.target.value)}
                      />
                    </div>
                    <button className={styles.authButtonWaitlist} onClick={confirmSignUp}>Confirm</button>
                  </form>
                </div>
              </div>
            </>
          }
        </div>

      </div>
      <div className={styles.authRight}>
        <div className={styles.authHowItWorksWrapper}>
          <div className={styles.authHowItWorks}>
            <div className={styles.authHowItWorksRow}>
              <div className={styles.icon1}></div>
              <div className={styles.authHowItWorksText}>
                <h3 className={styles.authHowItWorksTitle}>Curate Institutional Knowledge</h3>
                <p className={styles.authHowItWorksDescription}>A New Way to Experience Your Policy and Procedure Documents</p>
              </div>
            </div>
            <div className={styles.authHowItWorksRow}>
              <div className={styles.icon2}></div>
              <div className={styles.authHowItWorksText}>
                <h3 className={styles.authHowItWorksTitle}>Improve Efficiency Across your Front and Back Office</h3>
                <p className={styles.authHowItWorksDescription}>Branch, Call Center, and LO Channel</p>
              </div>
            </div>
            <div className={styles.authHowItWorksRow}>
              <div className={styles.icon3}></div>
              <div className={styles.authHowItWorksText}>
                <h3 className={styles.authHowItWorksTitle}>Empower Your Organization</h3>
                <p className={styles.authHowItWorksDescription}>Reduce Call Times and Increase Member Satisfaction</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;