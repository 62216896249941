import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ThreeDots } from '../../../../images/svg/threedots';
import sendButtonImg from '../../../../images/send-button.png';
import { Input } from '../../../../components/input/input';
import { sendGAEvent } from '../../../../services/gaService';
import { postQuery, createFeedback, getCollectionContext, getPosts, publishPostAPI } from '../../../../services/api-service';
import Modal from 'react-modal';
import { RiClipboardLine, RiThumbUpLine, RiThumbDownLine, RiStopLine, RiEdit2Line } from 'react-icons/ri';
import LoaderDots from '../../../../components/LoaderDots/LoaderDots';
import BlinkingIndicator from '../../../../components/BlinkingIndicator/BlinkingIndicator';
import styles from "./ChatWindow.module.css";
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import showdown from 'showdown';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

export const ChatWindow = ({ isFetching, messages, setMessages, collectionDetails, template }) => {
  const { collection_id } = useParams();
  const cancelStreamRef = useRef(null); // Use a useRef hook instead of a state variable
  const [feedbackMap, setFeedbackMap] = useState({ show: false, type: null, id: null });
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackType, setFeedbackType] = useState(null);
  //const [messages, setMessages] = useState([]); // This state hook is used to store an array of chat messages that are displayed on the chat page. Whenever a new message is sent or received, this state is updated to reflect the latest messages in the chat.
  const [currentMessageIndex, setCurrentMessageIndex] = useState(-1);
  const chatBoxRef = useRef(null); // provides a reference to the chat box element (used for scrolling to the bottom of the chat)
  const [userScrolledUp, setUserScrolledUp] = useState(false);
  const [isFetchingContext, setIsFetchingContext] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // Add this state variable
  const [inputText, setInputText] = useState(""); // stores the user's input text (e.g. the message they are typing)
  const programmaticScrollRef = useRef(false); // Add this useRef to track programmatic scrolling
  const prevScrollHeightRef = useRef(null); // Add this useRef to store the previous scroll height
  const [showSummaryButton, setShowSummaryButton] = useState(true);
  const [clickedThumbs, setClickedThumbs] = useState({});
  const navigate = useNavigate();
  const [editingMessage, setEditingMessage] = useState({ isEditing: false, index: -1, content: '' });

  useEffect(() => {
    setMessages([]);
    setShowSummaryButton(true);
    setClickedThumbs({});
    //fetchPosts();
  }, [collection_id]);

  useEffect(() => {
    return () => {
      if (cancelStreamRef.current) {
        cancelStreamRef.current();
        setCurrentMessageIndex(-1);
      }

      setIsSubmitted(false);
    };
  }, [collection_id]);

  useEffect(() => {
    const { scrollTop, scrollHeight, clientHeight } = chatBoxRef.current;
    chatBoxRef.current.scrollTo({
      top: scrollHeight,
      behavior: 'smooth',
    });
  }, [messages.length]);

  useEffect(() => {
    if (chatBoxRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatBoxRef.current;

      // Check if the scroll bar is at the bottom and the window grows larger than the current scroll
      if (
        prevScrollHeightRef.current &&
        scrollTop + clientHeight + 25 >= prevScrollHeightRef.current &&
        scrollHeight > prevScrollHeightRef.current
      ) {
        chatBoxRef.current.scrollTo({
          top: scrollHeight,
          behavior: 'smooth',
        });
      }

      prevScrollHeightRef.current = scrollHeight; // Update the previous scroll height
    }
  }, [messages]);


  const fetchPosts = async () => {
    const posts = await getPosts(collection_id);
    const mm = posts.map(item => ({
      text: item.content,
      id: item.post_id,
      published: item.status,
      tt: "post",
      align: "left",
      type: "bot"
    }));
    setMessages(mm);
  };


  const handleThumbClick = (type, id) => {
    if (clickedThumbs[id] === undefined) {
      setClickedThumbs({ ...clickedThumbs, [id]: type });
      setFeedbackMap({ show: true, type, id });
    }
  };

  const handleScroll = () => {
    if (chatBoxRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatBoxRef.current;
      // Check if the scroll event was triggered by the user or by the program
      if (programmaticScrollRef.current) {
        programmaticScrollRef.current = false; // Reset the flag
      } else {
        setUserScrolledUp(scrollTop + clientHeight < scrollHeight);
      }
    }
  };

  const handleChatSubmit = (event) => {
    event.preventDefault();

    if (inputText.trim() === "") return;
    sendGAEvent('submit_query', 'chat_page', 'form_submission');

    // Set isSubmitted to true when the user submits the text
    setUserScrolledUp(false);
    setIsSubmitted(true);

    const userInput = inputText;
    setInputText("");
    setMessages((prev) => [
      ...prev,
      { text: userInput, align: "right", type: "user" },
    ]);
    setMessages((prev) => [
      ...prev,
      { text: <BlinkingIndicator />, align: "left", type: "bot" },
    ]);
    console.log(template);
    const cancel = postQuery(collection_id, userInput, true, messages, setMessages, setCurrentMessageIndex, setIsSubmitted, template).then((cancel) => {
      cancelStreamRef.current = cancel; // Store the cancel function in the useRef
    });
  };

  const handleCancel = () => {
    // Set isSubmitted back to false when the cancel button is clicked
    setIsSubmitted(false);

    // Call the cancel function to cancel the event stream
    if (cancelStreamRef.current) {
      cancelStreamRef.current();
      setCurrentMessageIndex(-1);
    }
  };

  const handleFeedback = () => {
    const type = (feedbackMap.type === 1) ? "positive" : "negative";
    createFeedback(collection_id, feedbackMap.id, type, feedbackMessage);
    setFeedbackMessage("");
    setFeedbackMap({ show: false, type: null, id: null });
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Text copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text to clipboard: ", err);
      }
    );
  };

  const collectionContext = () => {
    sendGAEvent('get_context', 'chat_page', 'form_submission');

    // Set isSubmitted to true when the user submits the text
    setUserScrolledUp(false);
    setIsSubmitted(true);
    setShowSummaryButton(false);

    setMessages((prev) => [
      ...prev,
      { text: <BlinkingIndicator />, align: "left", type: "bot", id: "summary" },
    ]);

    const cancel = getCollectionContext(collection_id, messages, setMessages, setCurrentMessageIndex, setIsSubmitted).then((cancel) => {
      cancelStreamRef.current = cancel; // Store the cancel function in the useRef
    });
  }

  const fixIt = (message) => {
    let resp = String(message);
    resp = resp.replace(/<br>\n/g, '  \n').replace(/\n\n/g, '\n');
    return resp
  }

  const handleEditClick = (index, content) => {
    setEditingMessage({ isEditing: !editingMessage.isEditing, index: index, content: content });
  };

  const publishPost = async (postId) => {
    publishPostAPI(postId)
    fetchPosts();
  }

  return (
    <div className="chat">
      <Modal isOpen={feedbackMap.show} style={{ overlay: { zIndex: 10 } }}>
        <div className={styles.modalDiv}>
          <div className={styles.header}>
            {(feedbackMap.type === 1) ? <RiThumbUpLine className={styles.RiThumbUpLine + " " + styles.icon} /> : <RiThumbDownLine className={styles.RiThumbDownLine + " " + styles.icon} />}
            <h5>Provide additional feedback</h5>
          </div>
          <div className={styles.line}></div>
          {/*<input
            className={styles.input}
            onChange={(e) => setFeedbackMessage(e.target.value)}
            type="text"
            value={feedbackMessage}
            placeholder={(feedbackMap.type === 1) ? "What do you like about the response?" : "What is wrong about the response?"}
  />*/}
          <textarea
            className={styles.input}
            onChange={(e) => setFeedbackMessage(e.target.value)}
            value={feedbackMessage}
            placeholder={
              feedbackMap.type === 1
                ? "What do you like about the response?"
                : "What is wrong about the response?"
            }
            rows={3} // Adjust the number of rows as needed
          />
          <div className={styles.buttons}>
            <button className={styles.submitButton} onClick={() => handleFeedback()}>Submit</button>
            <button onClick={() => setFeedbackMap({ show: false, type: null, id: null })}>Cancel</button>
          </div>
        </div>
      </Modal>
      {/* Chat box */}
      <div ref={chatBoxRef} className="chat-box" onScroll={handleScroll}>
        {showSummaryButton && (
          <div className="message left bg-lightblue parentDiv" style={{ whiteSpace: 'pre-wrap' }}>
            <div className="textDiv">
              Welcome to <strong>{collectionDetails.name}</strong>. Learn more about the contents of this collection by clicking “Summarize” below or start asking questions.
              <br /><br />
              <button className="summaryButton" onClick={collectionContext}>Summarize Collection</button>
            </div>
          </div>
        )}
        {!isFetching && (
          <>
            {messages.map((message, index) => (
              <div
                key={index}
                className={`message ${message.align} ${message.align === 'left' ? 'bg-lightblue' : 'bg-lightgray'
                  } parentDiv`}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                <div className="textDiv" style={{ width: '85%' }} >
                  <span>
                    <strong>
                      {message.status}
                    </strong>
                    {editingMessage.isEditing && editingMessage.index === index ? (
                      <input
                        value={editingMessage.content}
                        onChange={(e) => setEditingMessage({ ...editingMessage, content: e.target.value })}
                      />
                    ) : (
                      message.text
                    )}
                    {(message.paywall) && (
                      <>
                        <br /><br />
                        <button className="summaryButton" onClick={() => navigate("/upgrade")}>Upgrade Your Plan</button>
                      </>)}
                    {/*message.tags !== undefined && Array.isArray(message.tags) && (
                      <div>
                        <br />
                        {message.tags.map((tag, index) => (
                          <button className={styles.tagButton} key={index} disabled>
                            {tag}
                          </button>
                        ))}
                      </div>
                        )*/}
                    {/*{isSubmitted && message.type === "bot" && index === messages.length - 1 && <BlinkingIndicator />}*/}
                  </span>
                </div>
                {(message.type === "bot" && message.id !== undefined && !message.paywall) && (
                  <div className={styles.actionsDiv}>
                    {/*<RiEdit2Line
                      className={styles.iconStyle}
                      size="20px"
                      onClick={() => handleEditClick(index, message.text)}
                />*/}
                    <RiClipboardLine className={styles.iconStyle} onClick={() => copyToClipboard(message.text)} size="20px" />
                    <RiThumbUpLine
                      className={`${styles.iconStyle} ${clickedThumbs[message.id] === 1 ? styles.iconSubmitted : ""}`}
                      onClick={() => handleThumbClick(1, message.id)}
                      size="20px"
                      style={{ display: clickedThumbs[message.id] === 0 ? "none" : "inline" }}
                    />
                    <RiThumbDownLine
                      className={`${styles.iconStyle} ${clickedThumbs[message.id] === 0 ? styles.iconSubmitted : ""}`}
                      onClick={() => handleThumbClick(0, message.id)}
                      size="20px"
                      style={{ display: clickedThumbs[message.id] === 1 ? "none" : "inline" }}
                    />
                  </div>)}
                {(message.tt === "post") && (<div>
                  <button className="summaryButton" onClick={() => publishPost(message.id)}>
                    {message.published === 1 ? "Unpublish" : "Publish"}
                  </button>
                </div>)}
              </div>
            ))}
          </>
        )}
        {isSubmitted && (
          <button onClick={handleCancel} className="cancel-chat-button">
            <RiStopLine /> Stop Generating
          </button>
        )}
      </div>
      {/* Container for chat input field and submit button */}
      <div className="chat-input-container">
        <form onSubmit={(event) => handleChatSubmit(event)}>
          <Input
            onChange={(e) => setInputText(e.target.value)}
            type="text"
            value={inputText}
            placeholder="Ask a question about this collection ..."
            icon={isSubmitted ? <LoaderDots /> : <img src={sendButtonImg} alt="send-button" />}
            disabled={isSubmitted}
          />
        </form>
      </div>
    </div>
  );
};

export default ChatWindow;