import { CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { API_URL } from '../config';
import { setAuthToken, getAmplifySessionHeaders } from './auth-service';

async function isTokenExpired() {

  const expirationTime = parseInt(localStorage.getItem("_tkExpiry"));
  const currentTime = Math.floor(Date.now() / 1000);

  return currentTime >= expirationTime;
}

async function refreshToken() {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const refreshToken = localStorage.getItem('_refreshTk');
    const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });
    await cognitoUser.refreshSession(cognitoRefreshToken, (err, session) => {
      console.log(session);
      setAuthToken(session.idToken.jwtToken, session.refreshToken.token, session.idToken.getExpiration());
    });
    console.log('Token has been refreshed');
  } catch (error) {
    console.error('Error refreshing token:', error);
    window.location.href = `${window.location.origin}/login`;
  }
}

async function ensureValidSession() {
  try {
    const tokenExpired = await isTokenExpired();

    if (tokenExpired) {
      await refreshToken();
    }
  } catch (error) {
    console.error('Error ensuring valid session:', error);
    await Auth.signOut();
  }
}

export async function createCollection(name) {
  await ensureValidSession();
  const headers = await getAmplifySessionHeaders();

  try {
    const response = await fetch(`${API_URL}/collections/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ name }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating collection:', error);
    throw error;
  }
}

export async function getCollections() {
  await ensureValidSession();
  const headers = await getAmplifySessionHeaders();

  return fetch(`${API_URL}/collections/`, {
    method: 'GET',
    headers,
  }).then(res => res.json());
}

let currentReader = null;
export async function getCollectionDocs(collection_id) {
  await ensureValidSession();
  const headers = await getAmplifySessionHeaders();

  if (currentReader != null) {
    console.log("cancelling previous reader");
    currentReader.cancel();
  }

  return fetch(`${API_URL}/collections/${collection_id}/documents/`, {
    method: 'GET',
    headers,
  }).then(res => res.json());
}

// export async function inputPrompt(collectionId, message) {
//     await ensureValidSession();
//     const headers = await getAmplifySessionHeaders();

//     const data = {
//       query: message,
//     };

//     return fetch(`${API_URL}/collections/${collectionId}/query/`, {
//       method: 'POST',
//       headers,
//       body: JSON.stringify(data),
//     }).then(res => res.text()).then(body => JSON.parse(body));
//   }

export async function inputPrompt(collectionId, message, stream = false, messageHandler) {
  await ensureValidSession();
  const headers = await getAmplifySessionHeaders();

  const data = {
    query: message,
    stream,
  };

  const response = await fetch(`${API_URL}/collections/${collectionId}/query/`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });

  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let buffer = "";

  async function readStream() {
    const { value, done } = await reader.read();
    if (done) {
      console.log("Stream finished.");
      return;
    }

    const chunk = decoder.decode(value);
    buffer += chunk;

    let endIdx = buffer.lastIndexOf("\n");

    if (endIdx !== -1) {
      const parseableData = buffer.slice(0, endIdx + 1);
      buffer = buffer.slice(endIdx + 1);

      const lines = parseableData.split("\n");
      for (const line of lines) {
        if (line.trim() === "") {
          continue;
        }

        let data;
        try {
          data = JSON.parse(line);
        } catch (err) {
          console.error("Failed to parse chunk: ", err);
          continue;
        }

        if (data.stop) {
          console.log("Stream stopped.");
          return;
        } else {
          // console.log("Data: ", data.data);
          messageHandler(data.data);
        }
      }
    }

    readStream();
  }

  readStream();
}


export async function addUrlToCollection(collectionId, url) {
  await ensureValidSession();
  const headers = await getAmplifySessionHeaders();

  const data = {
    url
  }

  return fetch(`${API_URL}/collections/${collectionId}/web_document/`, {
    method: `POST`,
    headers,
    body: JSON.stringify(data)
  }).then(res => res.text()).then(body => JSON.parse(body));
}

export async function editCollection(collectionId, name) {
  await ensureValidSession();
  const headers = await getAmplifySessionHeaders();

  const data = {
    name
  }
  return fetch(`${API_URL}/collections/${collectionId}`, {
    method: `PUT`,
    headers,
    body: JSON.stringify(data)
  }).then(res => res.json());
}

export async function deleteCollection(collectionId) {
  await ensureValidSession();
  const headers = await getAmplifySessionHeaders();

  return fetch(`${API_URL}/collections/${collectionId}`, {
    method: `DELETE`,
    headers
  }).then(res => res.json());
}

export const uploadPdfToCollection = async (collectionId, file) => {
  await ensureValidSession();
  const headers = await getAmplifySessionHeaders();

  // Remove 'Content-Type' from headers for file uploads
  delete headers["Content-Type"];

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(
    `${API_URL}/collections/${collectionId}/pdf_document/`,
    {
      method: "POST",
      headers,
      body: formData,
    }
  );

  if (!response.ok) {
    throw new Error(response.status);
  }

  return response.json();
};

// Add a new function to call the API/collections/{collection_id}/prompt/
export async function getCollectionPrompt(collectionId) {
  await ensureValidSession();
  const headers = await getAmplifySessionHeaders();

  return fetch(`${API_URL}/collections/${collectionId}/prompt/`, {
    method: 'GET',
    headers,
  }).then(res => res.json());
}

export async function editCollectionPrompt(collectionId, text) {
  await ensureValidSession();
  const headers = await getAmplifySessionHeaders();

  const data = {
    text
  };

  return fetch(`${API_URL}/collections/${collectionId}/prompt/`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data)
  }).then(res => res.json());
}

// export async function getCollectionContext(collectionId) {
//   await ensureValidSession();
//   const headers = await getAmplifySessionHeaders();

//   return fetch(`${API_URL}/collections/${collectionId}/context/`, {
//     method: 'GET',
//     headers,
//   }).then(res => res.json());
// }

// const readerLock = new Promise(res => res());
export async function getCollectionContextStream(collectionId, messageHandler) {
  await ensureValidSession();
  const headers = await getAmplifySessionHeaders();

  const data = {
    stream: true,
  };

  const response = await fetch(`${API_URL}/collections/${collectionId}/context/`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });

  console.log(`current reader ${currentReader}`);

  if (currentReader != null) {
    console.log("cancelling previous reader");
    currentReader.cancel();
  }

  currentReader = response.body.getReader();


  readStream(messageHandler);
}


async function readStream(messageHandler) {

  const decoder = new TextDecoder();
  let buffer = "";

  const { value, done } = await currentReader.read();
  if (done) {
    console.log("Stream finished.");
    currentReader = null;
    return;
  }

  const chunk = decoder.decode(value);
  buffer += chunk;

  let endIdx = buffer.lastIndexOf("\n");

  if (endIdx !== -1) {
    const parseableData = buffer.slice(0, endIdx + 1);
    buffer = buffer.slice(endIdx + 1);

    const lines = parseableData.split("\n");
    for (const line of lines) {
      if (line.trim() === "") {
        continue;
      }

      let data;
      try {
        data = JSON.parse(line);
      } catch (err) {
        console.error("Failed to parse chunk: ", err);
        continue;
      }

      if (data.stop) {
        console.log("DATA STOPPED");
        return;
      } else {
        messageHandler(data.data);
      }
    }
  }

  readStream(messageHandler);
}