import "./input.css";

export const Input = ({
    type,
    onChange,
    value,
    className,
    placeholder,
    icon,
    disabled,
}) => {
    return (
        <div className="input-field-container">
            <input 
            type={type} 
            onChange={onChange}
            value={value}
            className={`chat-input ${className}`}
            placeholder={placeholder}
            disabled={disabled}
            />
            {icon && <button className="send-button">{icon}</button>}
        </div>
    )
}
