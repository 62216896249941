import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { deactivatePlan, reactivatePlan } from '../../services/api-service';
import styles from './SettingsPage.module.css';

function SettingsPage({ currentUser, fetchUser }) {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [resetPasswordMessage, setResetPasswordMessage] = useState('');
  const [deactivatePlanMessage, setDeactivatePlanMessage] = useState('');
  const [resetClicked, setResetClicked] = useState(false);
  const navigate = useNavigate();

  const membershipMap = {
    "0": "Free Tier",
    "1": "Individual",
    "2": "Professional",
    "-1": "Enterprise",
  };

  const handleResetPassword = async () => {
    try {
      await Auth.forgotPassword(currentUser.user.email);
      setResetPasswordMessage('Confirmation code sent to your email');
      setResetClicked(true);
    } catch (error) {
      console.error('Error resetting password', error);
      setResetPasswordMessage('Error resetting password');
    }
  };

  const handleConfirmNewPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(currentUser.user.email, code, password);
      setResetPasswordMessage('Password changed successfully');
    } catch (error) {
      console.error('Error changing password', error);
      setResetPasswordMessage('Error changing password');
    }
  };

  const handleDeactivatePlan = async () => {
    try {
      await deactivatePlan();
      await fetchUser();
      setDeactivatePlanMessage(`Plan cancellation confirmed. Services remain active until ${currentUser.renewal_date}. Reactivate to continue usage post-expiry.`);
    } catch (error) {
      console.error('Error deactivating plan', error);
      setDeactivatePlanMessage('Error deactivating plan');
    }
  };

  const handleReactivatePlan = async () => {
    try {
      await reactivatePlan();
      await fetchUser();
      setDeactivatePlanMessage(`Plan reactivation confirmed. Services will auto-renew on ${currentUser.renewal_date}.`);
    } catch (error) {
      console.error('Error reactyivating plan', error);
      setDeactivatePlanMessage('Error reactivating plan');
    }
  };

  if (currentUser === {}) return <p>Loading...</p>;

  return (
    <div className={styles.container}>
      <button onClick={() => navigate('/collections')} className={styles.backButton}>Back</button>
      <h1 className={styles.title}>Settings</h1>

      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>User Information</h2>
        <p className={styles.userInfo}><strong>Family Name:</strong> {currentUser.user.family_name}</p>
        <p className={styles.userInfo}><strong>Given Name:</strong> {currentUser.user.given_name}</p>
        <p className={styles.userInfo}><strong>Email:</strong> {currentUser.user.email}</p>
        <p className={styles.userInfo}><strong>Organization Name:</strong> {currentUser.current_org.name}</p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>Reset Password</h2>
        <button onClick={handleResetPassword} className={styles.button}>Reset Password</button>
        {resetClicked && (
          <>
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter confirmation code"
              className={styles.input}
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter new password"
              className={styles.input}
            />
            <button onClick={handleConfirmNewPassword} className={styles.button}>Confirm New Password</button>
          </>
        )}
        {resetPasswordMessage && <div className={styles.message}>{resetPasswordMessage}</div>}
      </section>
      {(currentUser.ui_settings.show_upgrade) &&
        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>Subscription</h2>
          <p className={styles.subscriptionInfo}>Current Subscription: {membershipMap[currentUser.membership]}</p>
          {(currentUser.membership !== 0) && (currentUser.membership_status === "active") && (<p className={styles.subscriptionInfo}>Renewal Date: {currentUser.renewal_date}</p>)}
          {(currentUser.membership !== 0) && (currentUser.membership_status === "active") && (<p className={styles.subscriptionInfo}>Renewal Period: {currentUser.renewal_period}</p>)}
          {(currentUser.membership_status === "deactivated" || currentUser.membership_status === "cancelled") && (<p className={styles.subscriptionInfo}>Plan cancelled. Services remain active until {currentUser.renewal_date}. Reactivate to continue usage post-expiry.</p>)}
          {(currentUser.membership_status !== "deactivated" && currentUser.membership_status !== "cancelled") && (<button onClick={() => navigate('/upgrade')} className={styles.button}>Modify Plan</button>)}
          {(currentUser.membership !== 0) && (
            <>
              {(currentUser.membership_status === "active") && (<button onClick={handleDeactivatePlan} className={styles.button}>Deactivate Plan</button>)}
              {(currentUser.membership_status === "deactivated" || currentUser.membership_status === "cancelled") && (<button onClick={handleReactivatePlan} className={styles.button}>Reactivate Plan</button>)}
              {deactivatePlanMessage && <div className={styles.message}>{deactivatePlanMessage}</div>}
            </>
          )}
        </section>}
      <div style={{
        position: "fixed",
        width: "100%",
        bottom: "0",
        padding: "20px",
        textAlign: "center",
      }}>
        <p>If you need any help, feel free to contact us at <a href="mailto:support@senso.ai">support@senso.ai</a></p>
      </div>
    </div>
  );
}

export default SettingsPage;
