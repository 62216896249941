/**
This file defines the top-level App component for the REACT application.
It imports necessary components, such as the ChatPage and LoginPage, and renders them
within a container element.
*/

// Import React and necessary components
import React, { useState, useEffect } from 'react';
import './App.css';
import { ChatPage } from './pages/chat-page/chat-page';
import { LoginPage } from './pages/login-page/login-page';
import CollectionPage from './pages/CollectionPage';
import PasswordReset from './pages/SettingsPage/PasswordReset';
import NCUAQuery from './pages/ncua/NCUAQuery';
import TestStripe from './pages/TestStripe';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignUp from './pages/SignUp/SignUp';
import { IoMegaphoneOutline } from "react-icons/io5";
import { RiChat2Line, RiSettings3Line, RiLogoutBoxLine, RiArchiveDrawerLine, RiBarChart2Line, RiAccountCircleLine } from 'react-icons/ri';
import Modal from 'react-modal';
import TemplateManager from './pages/TemplateManager/TemplateManager';
import AnalyticsPage from './pages/AnalyticsPage/AnalyticsPage';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from "./App.module.css";
import UserManager from './pages/UserManager/UserManager';
import DocumentManager from './pages/DocumentManager/DocumentManager';
import CollectionFeed from './pages/CollectionFeed/CollectionFeed';
import { getUserDetails } from './services/api/userAPI';
import { ToastContainer } from 'react-toastify';

// Set the document title outside of the App component
document.title = "CU Copilot";
Modal.setAppElement('#root');

// Define the App component
const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({ ui_settings: {}, user: {}, current_org: {} });
  const navigate = useNavigate();
  const location = useLocation();

  //usePageView(); // use the custom hook here

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const checkUserSession = async () => {
    console.log("checkUserSession is being called"); // Add this
    const currentPath = window.location.pathname;
    console.log("-------------------------------------------------");
    console.log(currentPath);
    console.log("-------------------------------------------------");
    try {
      await Auth.currentSession();
      setIsAuthenticated(true);
      const fetchedUser = await getUserDetails();
      setCurrentUser(fetchedUser)
      if (currentPath === "/login" || currentPath === "/signup" || currentPath === "/") {
        navigate('/collections');
      }
    } catch (error) {
      setIsAuthenticated(false);
      if (currentPath !== "/login" && currentPath !== "/signup" && currentPath !== "/") {
        sessionStorage.setItem('currentPath', JSON.stringify(currentPath));
        navigate('/login');
      }
    }
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const handleLogoutConfirmation = () => {
    setShowLogoutModal(false);
    handleLogout();
  };

  useEffect(() => {
    checkUserSession();
  }, [location.pathname]);

  // Render the App component
  return (
    <div className="container">
      <ToastContainer />
      <Modal isOpen={showLogoutModal}>
        <div className={`${styles.confirmationModal} ${styles.shadow}`}>
          <div className={styles.boxInModal}>
            <div className={styles.confirmationMessage}>Are you sure you want to log out?</div>
            <div className={styles.logoutRow}>
              <button className={styles.logoutButton} onClick={handleLogoutConfirmation}>Sign Out</button>
              <button className={styles.cancelButton} onClick={() => setShowLogoutModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>

      <div
        className={`side-nav-bar ${isMenuOpen ? 'open' : ''}`}
        onClick={toggleMenu}
      >
        <div className="menu-items child-a">
          {(currentUser.ui_settings.show_posts) && (<div><IoMegaphoneOutline className="nav-icon" size="32px" onClick={() => navigate('/posts')} /></div>)}
          {(currentUser.ui_settings.show_queries) && (<div><RiChat2Line className="nav-icon" size="32px" onClick={() => navigate('/collections')} /></div>)}
          {(currentUser.ui_settings.show_users) && (<div><RiAccountCircleLine className="nav-icon" size="32px" onClick={() => navigate('/users')} /></div>)}
          {(currentUser.ui_settings.show_documents) && (<div><RiArchiveDrawerLine className="nav-icon" size="32px" onClick={() => navigate('/documents')} /></div>)}
          {(currentUser.ui_settings.show_analytics) && (<div><RiBarChart2Line className="nav-icon" size="32px" onClick={() => navigate('/analytics')} /></div>)}
        </div>
        <div className="menu-items child-b">
          <div><RiSettings3Line className="nav-icon" size="32px" onClick={() => navigate('/settings')} /></div>
          <div><RiLogoutBoxLine className="nav-icon" size="32px" onClick={() => setShowLogoutModal(true)} /></div>
        </div>
      </div>

      <Routes>
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/posts" element={<CollectionFeed />} />
        <Route path="/posts/:collection_id" element={<CollectionFeed />} />
        <Route exact path="/collections" element={<CollectionPage currentUser={currentUser} />} />
        <Route path="/collections/:collection_id" element={<CollectionPage currentUser={currentUser} />} />
        <Route path="/password_reset" element={<PasswordReset />} />
        <Route exact path="/upgrade" element={<TestStripe />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/password_reset" element={<PasswordReset />} />
        <Route exact path="/settings" element={<SettingsPage currentUser={currentUser} />} />
        <Route exact path="/ncua" element={<NCUAQuery />} />
        <Route exact path="/documents" element={<DocumentManager />} />
        <Route exact path="/users" element={<UserManager />} />
        <Route exact path="/analytics" element={<AnalyticsPage />} />
        <Route exact path="/templates" element={<TemplateManager />} />
      </Routes>
    </div>
  );
};

export default App;
