import { headers } from "../config";
import { Auth } from "aws-amplify";

export const setAuthToken = (token, refreshToken, expiry) => {
  headers.Authorization = `Bearer ${token}`;
  localStorage.setItem("_tk", token);
  localStorage.setItem("_refreshTk", refreshToken);
  localStorage.setItem("_tkExpiry", expiry);
};

export async function getAmplifySessionHeaders() {
  const session = await Auth.currentSession();
  const accessToken = session.getIdToken().getJwtToken();

  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`,
  };
}