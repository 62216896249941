import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { RiShareLine, RiArchiveDrawerLine, RiFileAddLine, RiMenuLine, RiProfileLine, RiHonourLine } from 'react-icons/ri';
import { listCollections, getCollection, addWebDocument, putCollection, putCollectionVisibility, collectionSubscribe } from '../../services/api-service';
import NewCollection from './components/NewCollection/NewCollection';
import SideBar from './components/SideBar/SideBar';
import ChatWindow from './components/ChatWindow/ChatWindow';
import DocumentManager from './components/DocumentManager/DocumentManager';
import ShareManager from './components/ShareManager/ShareManager';
import Modal from 'react-modal';
import { AddContentModal } from './components/AddContent/AddContent';
import { sendGAEvent } from '../../services/gaService';
import { getUser, listTemplates } from '../../services/api-service';
import { useNavigate } from 'react-router-dom';
import DocumentListButton from './components/DocumentListButton/DocumentListButton';
import "./style.css";

const DropDownMenu = ({
  handleShowAddDocumentModal,
  handleShowManageDocumentModal,
  handleShowSharingModal,
  closeMenu,
}) => {
  const menuRef = useRef();

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuOptionClick = (menuOptionFn) => {
    menuOptionFn(true);
    closeMenu();
  };

  return (
    <div className="drop-down-menu" ref={menuRef}>
      <div onClick={() => handleMenuOptionClick(handleShowAddDocumentModal)}>
        Add Document
      </div>
      <div onClick={() => handleMenuOptionClick(handleShowManageDocumentModal)}>
        Manage Documents
      </div>
      {/*<div onClick={() => handleMenuOptionClick(handleShowSharingModal)}>
        Sharing
      </div>*/}
    </div>
  );
};

const CollectionPage = ({ currentUser }) => {
  const { collection_id } = useParams();
  const [collectionDetails, setCollectionDetails] = useState({});
  const [collectionName, setCollectionName] = useState(null);
  const [isFetching, setIsFetching] = useState(true); // This state hook is used to track whether a fetch request is currently in progress. This is important to prevent multiple simultaneous fetch requests, which could lead to unpredictable behavior.
  const [collections, setCollections] = useState([]); // stores an array of the user's collections
  const [showManageDocumentModal, setShowManageDocumentModal] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');
  const [showManageShareModal, setShowManageShareModal] = useState(false);
  const [showAddContentModal, setShowAddContentModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [fetchingDetails, setFetchingDetails] = useState(true);

  const options = [
    { name: 'Content Writer', value: 'aae5d170-d08a-4f0b-a1a7-0e35350583c4' },
    { name: 'Original Prompt', value: '564ada86-6dd4-4a13-8fad-406b5663e5bd' },
    { name: 'Semantic Search', value: '8d9999ec-9838-422e-898a-fdebf6ed7392' }
  ];

  const fetchTemplates = async () => {
    const result = await listTemplates();
    console.log(result);
    setSelectedValue(result[0].template_id);
    setTemplates(result);
  }

  useEffect(() => {
    {/*setIsFetching(true);*/ }
    if (collection_id !== undefined) {
      getCollectionDetails();
      fetchTemplates();
    } else {
      setCollectionName("Create New Collection");
    }
    fetchCollections();
    setIsSidebarOpen(false)
    {/*setTimeout(() => {
      setIsFetching(false)
    },200);*/}
  }, [collection_id]);

  const getCollectionDetails = async () => {
    setFetchingDetails(true);
    try {
      const fetchedCollection = await getCollection(collection_id);
      setCollectionDetails(fetchedCollection);
      setCollectionName(fetchedCollection.name);
      setFetchingDetails(false);
    } catch (error) {
      console.error('Error fetching collection details:', error);
      if (error === "No current user") {
        navigate("/login");
      }
    }
  };

  // All Collections - Function to fetch all collections from repository service
  const fetchCollections = async () => {
    try {
      const fetchedCollections = await listCollections();
      setCollections(fetchedCollections);
    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };

  async function handleAddUrlClick(url) {
    setDocumentUrl('');
    try {
      sendGAEvent('add_document', 'chat_page', 'web_document');
      const data = await addWebDocument(collection_id, url);
      const successMessage = {
        text: <div>
          <strong>successfully added {url}</strong>
          <p>{data.summary}</p>
        </div>,
        align: 'left'
      }
      setMessages((prev) => [...prev, successMessage]);
    } catch (e) {
      const errorMessage = {
        text: <span className='text-danger'>Error trying to submit new url to collection. Please try a different url.</span>,
        align: 'left'
      }
      setMessages((prev) => [...prev, errorMessage]);
    }
  }

  const onDocumentUpload = (summary) => {
    setMessages((prev) =>
      [...prev, { text: summary, align: 'left' }]);
  }

  async function submitCollectionName() {
    // don't call api if the name hasn't changed
    if (collectionName === collectionDetails.name) {
      return;
    }
    try {
      let newName = collectionName;
      console.log(`colleciton name is ${newName}`);
      if (collectionName === '') {
        newName = "New Collection";
      }
      const res = await putCollection(collection_id, newName);
      fetchCollections();
      getCollectionDetails();
    } catch (e) {
      console.log("error when changing name");
    }
  }

  async function handleCheckboxChange() {
    setCollectionDetails(prevState => ({
      ...prevState,
      visibility: prevState.visibility === 0 ? 1 : 0
    }));

    const res = await putCollectionVisibility(collection_id, !collectionDetails.visibility);
  };

  async function handleSubscribe() {
    const res = await collectionSubscribe(collection_id);
    getCollectionDetails();
    fetchTemplates();
    fetchCollections();
    setIsSidebarOpen(false);
  };

  if ((!collectionDetails.subscribed) && (collection_id !== undefined) && (!fetchingDetails)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <h1>{collectionDetails.name}</h1>
        <button onClick={() => {handleSubscribe()}}>
          Subscribe
        </button>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className="collection-page">
        <Modal isOpen={showManageDocumentModal} style={{ overlay: { zIndex: 10 }, content: { marginTop: '100px', borderRadius: '10px' } }}>
          <DocumentListButton onClose={() => setShowManageDocumentModal(false)} />
        </Modal>

        <Modal isOpen={showManageShareModal} style={{ overlay: { zIndex: 10 }, content: { marginTop: '100px', borderRadius: '10px' } }}>
          <ShareManager
            onClose={() => setShowManageShareModal(false)}
            collectionName={collectionDetails.name}
            userPerm={collectionDetails.user_role}
          />
        </Modal>

        <Modal isOpen={showAddContentModal} style={{ overlay: { zIndex: 10 } }}>
          <AddContentModal
            onSubmit={handleAddUrlClick}
            onClose={() => setShowAddContentModal(false)}
            onDocumentUpload={onDocumentUpload}
          />
        </Modal>
        <SideBar collections={collections} setCollections={setCollections} isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} currentUser={currentUser} />
        <div className="chat-column">
          <div className="fixed-header">
            <div className="header-content">
              <div className="header-left">
                <input
                  className="header-title"
                  value={collectionName}
                  onChange={(e) => { setCollectionName(e.target.value) }}
                  onBlur={submitCollectionName}
                  disabled={(collection_id === undefined) || (collectionDetails.user_role < 2)}
                />
              </div>
            </div>
            {(collection_id !== undefined) && (collectionDetails.user_role > 0) &&
              <div className="header-right">
                <select
                  value={selectedValue}
                  onChange={e => setSelectedValue(e.target.value)}
                  className="template-select"
                >
                  {templates.map(template => (
                    <option key={template.template_id} value={template.template_id}>
                      {template.name}
                    </option>
                  ))}
                </select>
                <RiArchiveDrawerLine onClick={() => setShowManageDocumentModal(true)} color="black" size="25px" style={{ marginRight: '15px' }} />
                <RiShareLine onClick={() => setShowManageShareModal(true)} color="black" size="25px" style={{ marginRight: '15px' }} />
              </div>}
          </div>
          <div className="mobile-header">
            <div className="mobile-header-content">
              <div className="mobile-header-content-left">
                <RiMenuLine onClick={() => setIsSidebarOpen(true)} />
              </div>
              <div className="mobile-header-content-middle">
                <input
                  className="header-title"
                  value={collectionName}
                  onChange={(e) => { setCollectionName(e.target.value) }}
                  onBlur={submitCollectionName}
                  disabled={(collection_id === undefined) || (collectionDetails.user_role < 2)}
                />
              </div>
              <div className="mobile-header-content-right">
                {(collection_id !== undefined) && (collectionDetails.user_role > 0) && (
                  <div className="mobile-header-controls">
                    <div onClick={() => setIsDropDownOpen(!isDropDownOpen)}>Menu</div>
                  </div>
                )}
                {isDropDownOpen && (
                  <div>
                    <DropDownMenu
                      closeMenu={() => setIsDropDownOpen(false)}
                      handleShowAddDocumentModal={setShowAddContentModal}
                      handleShowManageDocumentModal={setShowManageDocumentModal}
                      handleShowSharingModal={setShowManageShareModal}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {collection_id !== undefined ? (
            <ChatWindow messages={messages} setMessages={setMessages} collectionDetails={collectionDetails} template={selectedValue} />
          ) : (
            <NewCollection collections={collections} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CollectionPage;