import React, { useState, useEffect, useRef } from 'react';
import { RiSettings4Line } from 'react-icons/ri';
import { AiOutlineEllipsis } from "react-icons/ai";
import styles from './PostSettings.module.css';

const PostSettings = ({ userPerm, status, onHide, onPublish, onEdit, onDelete }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <AiOutlineEllipsis
        className={styles.icon}
        onClick={() => setShowDropdown(!showDropdown)}
      />
      {showDropdown && (
        <div className={styles.dropdown}>
          <div className={styles.option} onClick={onHide}>Hide</div>
          {(userPerm >= 2) && (
            <>
              <div className={styles.option} onClick={onPublish}>{status === 0 ? "Publish" : "Unpublish"}</div>
              <div className={styles.option} onClick={onEdit}>Edit</div>
              <div className={styles.option} onClick={onDelete}>Delete</div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PostSettings;
