import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { BiLogOut } from "react-icons/bi";
import { RiChat2Line, RiSettings3Line, RiLogoutBoxLine, RiNotification2Line } from 'react-icons/ri';
import { deleteCollection } from "../../../../services/repository-service";
import styles from "./SideBar.module.css";

Modal.setAppElement('#root');

const SideBar = ({ collections, setCollections, isOpen, setIsOpen, currentUser }) => {
  const navigate = useNavigate();
  const { collection_id } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [collectionToDelete, setCollectionToDelete] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && event.target.nodeName === 'DIV') {
        console.log(event.target.nodeName);
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  const handleNewCollectionButtonClick = () => {
    setIsOpen(false);
    navigate('/collections');
  };

  const handleCollectionButtonClick = (collection) => {
    setIsOpen(false);
    navigate(`/collections/${collection.collection_id}`);
  };

  const handleDeleteCollection = (collection) => {
    setIsOpen(false);
    setShowDeleteModal(true);
    setCollectionToDelete(collection);
  };

  const confirmDeleteCollection = async () => {
    try {
      await deleteCollection(collectionToDelete.collection_id);
      const deleted = collectionToDelete.collection_id;
      setShowDeleteModal(false);
      setCollectionToDelete(null);
      setCollections(collections.filter((collection) => collection.collection_id !== collectionToDelete.collection_id));
      if (deleted === collection_id) {
        navigate('/collections');
      }
    } catch (e) {
      console.log(e);
      console.log("error deleting collection");
    }
  };

  return (
    <div className={`${styles.sidePanel} ${isOpen ? styles.sidePanelOpen : styles.sidePanelClosed}`}>
      <div className={`${styles.sidePanelContent} ${isOpen ? styles.sidePanelContentOpen : styles.sidePanelContentClosed}`}>
        <Modal isOpen={showDeleteModal}>
          <div className={`${styles.confirmationModal} ${styles.shadow}`}>
            <div className={styles.boxInModal}>
              <div className={styles.confirmationMessage}>Are you sure you want to delete <strong>{collectionToDelete?.name}</strong>?</div>
              <div className={styles.deleteRow}>
                <button className={styles.deleteButton} onClick={confirmDeleteCollection}>Delete</button>
                <button className={styles.cancelButton} onClick={() => setShowDeleteModal(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </Modal>
        <div className={styles.collectionsList}>
          <button className={`${styles.addCollectionButton} ${collection_id === undefined
            ? styles.selected
            : ""
            }`} onClick={handleNewCollectionButtonClick}>
            New Collection <span className={styles.addCollectionIcon}>+</span>
          </button>

          {collections.map((collection, index) => {
            return (
              <button
                className={`${styles.collectionBtn} ${styles.button} ${collection_id === collection.collection_id
                  ? styles.selected
                  : ""
                  }`}
                key={collection.collection_id}
                onClick={() => handleCollectionButtonClick(collection)}
              >
                <div className={styles.collectionContent}>
                  <span className={styles.collectionName}>{collection.name}</span>
                  {(collection.user_perm > 1) && (
                    <span
                      className={`${styles.deleteCollectionBtn} ${styles.deleteIcon}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteCollection(collection);
                      }}
                    ></span>
                  )}
                </div>
              </button>
            );
          })}
        </div>
        {(currentUser.ui_settings.show_upgrade) && (<div className={styles.upgradeList}>
          <button className={`${styles.upgradeButton}`} onClick={() => navigate("/upgrade")}>
            Upgrade
          </button>
        </div>)}
        {isOpen && (<div className="side-panel__options">
        <div onClick={() => { navigate("/settings"); }} className="side-panel__option">
            <RiSettings3Line /> <p>Settings</p>
          </div>
          <div onClick={() => { navigate("/login"); }} className="side-panel__option">
            <BiLogOut /> <p>Log Out</p>
          </div>
        </div>)}
      </div>
    </div>
  );
}

export default SideBar;