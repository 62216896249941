import { API_URL } from '../../config';
import { getAmplifySessionHeaders } from '../auth-service';

export async function createCollectionSummaryPost(collectionId) {
    const headers = await getAmplifySessionHeaders();

    try {
        const response = await fetch(`${API_URL}/posts/collections/${collectionId}/summary/`, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error creating collection:', error);
        throw error;
    }
}

export async function createCollectionQuestionsPost(collectionId) {
    const headers = await getAmplifySessionHeaders();

    try {
        const response = await fetch(`${API_URL}/posts/collections/${collectionId}/questions/`, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error creating collection:', error);
        throw error;
    }
}

export async function createCollectionUpdatesPost(collectionId) {
    const headers = await getAmplifySessionHeaders();

    try {
        const response = await fetch(`${API_URL}/posts/collections/${collectionId}/updates/`, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error creating collection:', error);
        throw error;
    }
}

export async function modifyPost(post_id, content) {
    const headers = await getAmplifySessionHeaders();

    try {
        const response = await fetch(`${API_URL}/posts/${post_id}/edit/`, {
            method: 'POST',
            body: JSON.stringify({ content: content }),
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error modifying publish status for post:', error);
        throw error;
    }
}

export async function postFeedback(post_id, type) {
    const headers = await getAmplifySessionHeaders();

    try {
        const response = await fetch(`${API_URL}/posts/${post_id}/feedback/`, {
            method: 'POST',
            body: JSON.stringify({ type: type }),
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error modifying feedback for post:', error);
        throw error;
    }
}

export async function publishPost(post_id) {
    const headers = await getAmplifySessionHeaders();

    try {
        const response = await fetch(`${API_URL}/posts/${post_id}/publish/`, {
            method: 'POST',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error modifying publish status for post:', error);
        throw error;
    }
}

export async function deletePost(post_id) {
    const headers = await getAmplifySessionHeaders();

    try {
        const response = await fetch(`${API_URL}/posts/${post_id}/delete/`, {
            method: 'DELETE',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error modifying publish status for post:', error);
        throw error;
    }
}

export async function getAllPosts(collection_id) {
    const headers = await getAmplifySessionHeaders();

    return fetch(`${API_URL}/posts/`, {
        method: 'GET',
        headers,
    }).then(res => res.json());
}

