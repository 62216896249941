import { API_URL } from '../../config';
import { getAmplifySessionHeaders } from '../auth-service';

export async function getUserDetails() {
    const headers = await getAmplifySessionHeaders();

    try {
        const response = await fetch(`${API_URL}/users/`, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error getting user details:', error);
        throw error;
    }
}
