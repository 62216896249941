import React, { useState, useEffect, useRef } from 'react';
import { Storage } from 'aws-amplify';
import { getAmplifySessionHeaders } from '../../services/auth-service';
import { BsFiletypePdf, BsLink45Deg, BsFile } from "react-icons/bs";
import { AiOutlinePlus, AiOutlineCloudUpload } from "react-icons/ai";
import { RiCloseLine, RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { API_URL } from '../../config';
import { addWebDocument2 } from '../../services/api-service';
import Modal from 'react-modal';
import moment from 'moment';
import { BeatLoader } from 'react-spinners';
import styles from './DocumentManager.module.css';

const DocumentManager = () => {
    const [url, setUrl] = useState("");
    const [documents, setDocuments] = useState([]);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [sortField, setSortField] = useState('created_at'); // Default sorting field
    const [sortDirection, setSortDirection] = useState('desc'); // Default sorting direction
    const [showOptions, setShowOptions] = useState(null);
    const [menuIndex, setMenuIndex] = useState(null);
    const [showSummaryModal, setShowSummaryModal] = useState(false);
    const [summary, setSummary] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const menuRef = useRef(null);

    Storage.configure({
        bucket: 'senso-static-files', // your bucket name here
        region: 'ca-central-1', // your bucket region here
        level: 'public' // or 'private', 'protected', etc.
    });

    useEffect(() => {
        setIsLoading(true);
        fetchDocuments();
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuIndex(null);
            }
        }
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);    

    async function handleAddUrlClick() {
        setUploadingFiles(prev => [...prev, url]);
        setShowUploadModal(false);
        try {
            const data = await addWebDocument2(url);
        } catch (e) {
            console.error('Error submitting URL:', e.message);
        } finally {
            setUploadingFiles(prev => prev.filter(name => name !== url));
            fetchDocuments();
            setUrl("");
        }
    }

    const fetchDocuments = async () => {
        setIsLoading(true);
        const headers = await getAmplifySessionHeaders();
        const response = await fetch(`${API_URL}/collections/documents/`, {
            method: 'GET',
            headers: headers
        });
        if (response.ok) {
            const data = await response.json();
            setDocuments(sortData(data, sortField, sortDirection));
        }
        setIsLoading(false);
    };

    // Function to sort data based on selected field and direction
    const sortData = (data, field, direction) => {
        return [...data].sort((a, b) => {
            if (field === 'created_at') {
                const aValue = moment(a[field]);
                const bValue = moment(b[field]);
                return direction === 'asc' ? aValue.diff(bValue) : bValue.diff(aValue);
            } else {
                const aValue = a[field];
                const bValue = b[field];
                return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
        });
    };

    // Function to handle sorting when clicked
    const handleSort = (field) => {
        const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(newDirection);
        setDocuments(sortData(documents, field, newDirection));
    };

    const onFileChange = event => {
        const file = event.target.files[0];
        uploadFile(file);
    };

    const onDrop = event => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        uploadFile(file);
    };

    const uploadFile = async file => {
        try {
            setUploadingFiles(prev => [...prev, file.name]);
            // Initialize progress state for this file
            setShowUploadModal(false);

            // Start the upload
            const headers = await getAmplifySessionHeaders();
            const startUploadResponse = await fetch(`${API_URL}/collections/start_upload/`, {
                method: 'POST',
                body: JSON.stringify({
                    filename: file.name,
                    content_type: file.type
                }),
                headers: headers
            });

            const presignedPostData = await startUploadResponse.json();

            const formData = new FormData();
            Object.keys(presignedPostData.fields).forEach(key => {
                formData.append(key, presignedPostData.fields[key]);
            });
            formData.append('file', file);

            // Use fetch API to upload the file to presigned URL
            const uploadResponse = await fetch(presignedPostData.url, {
                method: 'POST',
                body: formData
            });

            const completeUploadResponse = await fetch(`${API_URL}/collections/complete_upload/`, {
                method: 'POST',
                body: JSON.stringify({
                    filename: file.name
                }),
                headers: headers
            });

            if (!uploadResponse.ok) {
                throw new Error(`HTTP error! status: ${uploadResponse.status}`);
            } else {
                setUploadingFiles(prev => prev.filter(name => name !== file.name));
                setIsLoading(true);
                fetchDocuments();
            }
        } catch (error) {
            setUploadingFiles(prev => prev.filter(name => name !== file.name));
            console.log('Error uploading file: ', error);
        }
    };

    const handleViewSummary = async (documentId) => {
        setShowOptions(null);
        const headers = await getAmplifySessionHeaders();
        const response = await fetch(`${API_URL}/collections/documents/${documentId}/summary/`, {
            method: 'GET',
            headers: headers
        });
        if (response.ok) {
            const data = await response.json();
            setSummary(data.summary);
            setShowSummaryModal(true);
        }
    };

    const handleDeleteDocument = async (documentId) => {
        setShowOptions(null);
        const headers = await getAmplifySessionHeaders();
        await fetch(`${API_URL}/collections/documents/${documentId}/delete/`, {
            method: 'DELETE',
            headers: headers
        });
        fetchDocuments();
        setMenuIndex(null);
    };

    return (
        <div className={styles.mainDiv} onDragOver={(e) => e.preventDefault()} onDrop={!showUploadModal ? onDrop : undefined}>
            <Modal isOpen={showUploadModal} className={styles.modal} style={{ overlay: { zIndex: 10 } }}>
                <div className={styles.uploadDocumentModal}>
                    <div className={styles.modalCloseButton}>
                        <RiCloseLine onClick={() => setShowUploadModal(false)} size="2em" className={styles.closeIcon} />
                    </div>
                    <div className={styles.modalHeader}>
                        <h1 className={styles.modalTitle}>Upload Documents</h1>
                    </div>
                    <div className={styles.modalUploadSection} onDragOver={(e) => e.preventDefault()} onDrop={onDrop}>
                        <p className={styles.modalUploadIcon}><AiOutlineCloudUpload size="2em" /></p>
                        <p className={styles.modalUploadInstructions}>
                            Drag & Drop or <a
                                href='#'
                                onClick={(e) => {
                                    e.preventDefault();
                                    document.getElementById('fileInput').click();
                                }}
                            >Choose Files</a> to upload
                        </p>
                        <p className={styles.modalFileTypes}>pdf, txt, docx</p>
                        <input
                            type="file"
                            id="fileInput"
                            onChange={onFileChange}
                            style={{ display: "none" }}
                        />
                    </div>
                    <div className={styles.lineWithText}>
                        <div className={styles.lineLine}></div>
                        <span className={styles.lineText}>or</span>
                        <div className={styles.lineLine}></div>
                    </div>
                    <div className={styles.modalImportSection}>
                        <span className={styles.modalImportLabel}>Import from URL</span>
                        <input
                            className={styles.modalImportInput}
                            placeholder="Enter URL here"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}></input>
                        <button className={styles.modalImportButton} onClick={handleAddUrlClick}>Import</button>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={showSummaryModal} className={styles.modal} style={{ overlay: { zIndex: 10 } }}>
                <div className={styles.summaryModal}>
                    <div className={styles.modalCloseButton}>
                        <RiCloseLine onClick={() => setShowSummaryModal(false)} size="2em" className={styles.closeIcon} />
                    </div>
                    <div className={styles.modalHeader}>
                        <h1 className={styles.modalTitle}>Document Summary</h1>
                    </div>
                    <div className={styles.modalContent}>
                        <p>{summary}</p>
                    </div>
                </div>
            </Modal>
            <div className={styles.headerDiv}>
                <h1 className={styles.title}>Information Management</h1>
                <AiOutlinePlus className={styles.addIcon} size="2em" onClick={() => setShowUploadModal(true)} />
            </div>
            <div className={styles.contentDiv}>
                <table className={styles.contentTable}>
                    <thead className={styles.tableHead}>
                        <tr className={styles.headRow}>
                            <th className={styles.columnType}></th>
                            <th className={styles.columnTitle}>
                                Title
                                {sortField === 'title' && sortDirection === 'asc' ? <RiArrowUpSLine onClick={() => handleSort('title')} /> : <RiArrowDownSLine onClick={() => handleSort('title')} />}
                            </th>
                            <th className={styles.columnUploadDate}>
                                Upload Date
                                {sortField === 'created_at' && sortDirection === 'asc' ? <RiArrowUpSLine onClick={() => handleSort('created_at')} /> : <RiArrowDownSLine onClick={() => handleSort('created_at')} />}
                            </th>
                            <th className={styles.columnBlank}></th>
                            <th className={styles.columnBlank}></th>
                        </tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                        {uploadingFiles.map((file, index) => (
                            <tr className={styles.bodyRow} key={`uploading-${index}`}>
                                <td className={styles.typeCell}><AiOutlineCloudUpload size="1.5em" /></td>
                                <td className={styles.titleCell} title={file}>{file}</td>
                                <td className={styles.dateCell}>{moment().format('YYYY-MM-DD')}</td>
                                <td className={styles.viewDocumentCell}>Uploading...</td>
                                <td className={styles.optionsCell}>...</td>
                            </tr>
                        ))}
                        {documents.map((doc, index) => (
                            <tr className={styles.bodyRow} key={index}>
                                <td className={styles.typeCell}>{doc.type === 'file' ? <BsFiletypePdf className={styles.pdfIcon} size="1.5em" /> : doc.type === 'web' ? <BsLink45Deg className={styles.linkIcon} size="1.5em" /> : <BsFile className={styles.linkIcon} size="1.5em" />}</td>
                                <td className={styles.titleCell} title={doc.title}>{doc.title.length > 80 ? `${doc.title.substring(0, 80)}...` : doc.title}</td>
                                <td className={styles.dateCell}>{moment(doc.created_at).format('YYYY-MM-DD')}</td>
                                <td className={styles.viewDocumentCell}><a href={doc.url} target="_blank" rel="noopener noreferrer">VIEW DOCUMENT</a></td>
                                <td className={`${styles.optionsCell} ${styles.menuContainer}`}>
                                    <span onClick={() => setMenuIndex(menuIndex === index ? null : index)}>...</span>
                                    {menuIndex === index && (
                                        <div className={styles.optionsMenu} ref={menuRef}>
                                            <button onClick={() => handleViewSummary(doc.document_id)}>Summary</button>
                                            <button onClick={() => handleDeleteDocument(doc.document_id)}>Delete</button>
                                        </div>
                                    )}
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
                {(documents.length === 0) && (uploadingFiles.length === 0) && (isLoading === false) && (
                    <div className={styles.emptyDiv}>
                        <div className={styles.emptyPostMessage}>
                            There's no documents in this repository yet.
                        </div>
                    </div>
                )}
                {(isLoading === true) && (<div className={styles.isLoading}><BeatLoader /></div>)}
            </div>
        </div>

    );
};

export default DocumentManager;
